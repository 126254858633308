.l-page-industry,
.l-page-product-construction,
.l-page-product-line {
  background-color: white;
  margin-bottom: 0px;

  .l-benefit-area {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $color-10-warm-grey;

    .l-benefit-area .l-section-50:nth-of-type(2) {
      padding-right: 1rem;
    }

    .l-benefit-area .l-section-50:nth-of-type(3) {
      padding-left: 1rem;
    }

    .c-block-benefit-list-title {
      margin: 0 0 2rem 0;
      text-align: center;
      max-width: 100%;
      h2 {
        margin: 0 0 1rem 0;
        color: $color-blue-dark;
      }
    }
  }


  .l-benefits-area {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .l-benefit-area .l-section-50:nth-of-type(2) {
      padding-right: 1rem;
    }

    .l-benefit-area .l-section-50:nth-of-type(3) {
      padding-left: 1rem;
    }
    .c-block-benefit-list-title {
      margin: 0 0 2rem 0;
      text-align: left;
      max-width: 100%;
      h2 {
        margin: 0 0 1rem 0;
        color: $color-blue-dark;
      }
    }
  }
}

@include mediaSmallerThan(tablet) {
  .l-page-industry .l-benefit-area,
  .l-page-product-construction .l-benefit-area,
  .l-page-product-line .l-benefit-area {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .c-block-benefit-list-title {
    margin: 0 0 1rem 0;    
    h2 {
      margin: 0 0 1rem 0;
      font-size: 1.5rem;
      color: $color-blue-dark;
    }
  }
}
