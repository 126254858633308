.l-marketo-page {
  // styles to make hero banner full width inside fixed-width is-bound container
  .l-content.is-bound {
    .c-hero-banner2 {
      overflow: visible;
      height: 480px;
    }

    .c-hero-banner2-image-wrapper {
      &::after {
        content: '';
        position: absolute;
        background-image: var(--hero-image);
        background-size: cover;
        background-position: center;
        width: calc(100vw - var(--scrollbar-width));
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .c-hero-banner2-image {
      display: none;
    }

    .c-hero-banner2-content {
      position: absolute;
      right: unset;
      width: calc(100vw - var(--scrollbar-width));
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h2 {
    color: $color-blue;
    margin: 0;
    padding: 0;
  }
}

.marketo-form {
  width: 100% !important;

  .mktoFieldWrap {
    display: flex;
    flex-direction: column;
    color: $color-110-warm-grey;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &.mktoRequiredField {
      label {
        .mktoAsterix {
          color: $color-110-warm-grey !important;
        }
      }
    }

    &.mktoRequiredField.mktoCheckboxField {
      label {
        .mktoAsterix {
          float: left !important;
          padding-left: 0;
          padding-right: 5px;
        }
      }
    }

    .mktoCheckboxList {
      width: min-content !important;
      margin: 4px 0 !important;
      padding: 0 !important;

      input[type='checkbox'] {
        border: 1px solid #000;
        border-radius: 4px;
        height: 1.125rem;
        padding: 7px 5px;
        position: relative;
        top: 4px;
        width: 1.125rem;
        font-size: 16px;
        letter-spacing: 0;
        font-family: 'Roboto', sans-serif;
        outline: none;
        box-sizing: border-box;
        line-height: normal;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
        color: currentColor;
        display: grid;
        place-content: center;
        background-color: #fff;
      }

      input[type='checkbox']:focus {
        color: $color-110-warm-grey;
        box-shadow: 0 0 0 2px $color-blue;
        border-color: transparent;
        outline: 1px solid transparent;
      }

      input[type='checkbox']:checked {
        background-color: $color-blue;
      }

      input[type='checkbox']:checked:after {
        font-family: 'ALicons';
        content: '\e606';
        font-size: x-small;
        color: white;
        width: 11.198px;
        height: 11.198px;
      }
    }

    textarea {
      width: 100% !important;
      background-color: #fff;
      margin: 4px 0 !important;
      font-size: 16px;
      letter-spacing: 0;
      font-family: 'Roboto', sans-serif;
      border-radius: 3px;
      border: 1px solid $color-110-warm-grey;
      color: $color-110-warm-grey;
      padding: 8px 8px !important;
      box-sizing: border-box !important;
      line-height: normal !important;
      min-width: 145px;
      min-height: 112px;
      max-width: 100%;
      resize: vertical !important;
      outline: 0;

      &.mktoField {
        height: 37px;
      }

      &:focus {
        box-shadow: 0 0 0 2px $color-blue;
        border-color: transparent;
        outline: 1px solid transparent;
      }
    }

    select {
      width: 100% !important;
      border: 1px solid $color-110-warm-grey;
      border-radius: 3px;
      box-sizing: border-box;
      color: $color-110-warm-grey;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      height: 37px;
      letter-spacing: 0;
      line-height: normal;
      margin: 4px 0 10px 0;
      outline: none;
      padding: 6px;

      &:focus {
        border-color: $color-black;
        box-shadow: 0 0 4px $color-gray-dark;
      }
    }

    #LblLeadRole,
    #LblSalutation,
    #LblUnsubscribed {
      cursor: default !important;
    }

    .mktoLabel {
      width: 100% !important;
      color: $color-110-warm-grey;
      margin-top: 10px;
      padding-top: 0 !important;
      cursor: pointer;
      font-weight: 400 !important;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .mktoTextField,
    .mktoEmailField,
    .mktoTelField {
      width: 100% !important;
      margin: 4px 0 !important;
      border: 1px solid $color-110-warm-grey;
      height: 37px;
      font-family: 'Roboto', sans-serif;
      border-radius: 3px;
      font-size: 16px;
      padding: 8px 8px !important;
      outline: none;
      box-sizing: border-box !important;
      line-height: normal !important;
      letter-spacing: 0;
      color: $color-110-warm-grey;

      &:focus {
        box-shadow: 0 0 0 2px $color-blue;
        border-color: transparent;
        outline: 1px solid transparent;
      }
    }

    @media (max-width: 480px) {
      .mktoTextField,
      .mktoEmailField,
      .mktoTelField {
        height: 37px !important;
        font-size: 16px !important;
      }
    }
  }

  .mktoAsterix {
    color: $color-110-warm-grey !important;
  }

  .mktoFieldDescriptor {
    width: 100%;
    margin-bottom: 0 !important;
  }

  .mktoFieldWrap {
    width: 100%;
  }

  .mktoOffset {
    height: 0 !important;
  }

  .mktoGutter {
    height: 0 !important;
    width: 0 !important;
  }

  .mktoButtonRow {
    width: 100%;
  }

  .mktoButtonWrap {
    margin-left: 0 !important;
  }

  .mktoError {
    right: auto !important;

    .mktoErrorArrow {
      background-color: $color-blue !important;
      border: 1px solid $color-blue !important;
    }

    .mktoErrorMsg {
      background-color: $color-blue !important;
      background-image: -webkit-linear-gradient($color-blue 43%, $color-blue 100%) !important;
      background-image: -moz-linear-gradient($color-blue 43%, $color-blue 100%) !important;
      background-image: linear-gradient($color-blue 43%, $color-blue 100%) !important;
      background-image: -ms-linear-gradient($color-blue 43%, $color-blue 100%) !important;
      border: 1px solid $color-blue !important;
      -webkit-box-shadow: rgb(0 0 0 / 65%) 0 2px 7px, inset $color-blue 0 1px 0px !important;
      box-shadow: rgb(0 0 0 / 65%) 0 2px 7px, inset $color-blue 0 1px 0px !important;
      color: #ffffff !important;
      text-shadow: $color-blue 0 -1px 0 !important;
    }
  }

  .mktoButton {
    background: $color-blue !important;
    border-color: $color-blue !important;
    cursor: auto;
    height: 50px !important;
    font-size: 16px !important;
    padding: 16px 24px !important;
    justify-content: center;
    margin-top: 48px !important;
    width: fit-content;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    padding: 16px 24px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1.625;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;

    &:hover {
      background: $color-blue-dark !important;
    }
  }

  .mktoRadioList {
    width: 100% !important;
    margin: 4px 0 !important;
    padding: 0 !important;

    label {
      cursor: pointer;
      font-size: 1rem;
      margin: 0 0 12px 0 !important;
      position: relative;
      color: $color-black;
    }

    input[type='radio'] {
      display: none;
    }

    label::before {
      content: '';
      border: 1px solid #e6e3df;
      display: inline-block;
      width: 16.28px;
      height: 16.28px;
      border-radius: 50%;
      margin-right: 18px;
      margin-bottom: -4px;
    }

    label::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 10.27px;
      height: 10.27px;
      background: rgba(34, 34, 34, 0);
      left: 4px;
      top: 12.5px;
      margin: -8px 20px;
      margin-left: 0;
      border-radius: 50%;

      transition: all 0s;
    }

    input[type='radio']:checked + label::after {
      background: $color-blue;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }
}

@-moz-document url-prefix() {
  .marketo-form {
    .mktoRadioList {
      label::after {
        top: 13.5px;
      }
    }
  }
}

.mktoCheckboxField {
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 4px !important;

  label {
    margin-left: 20px !important;
    flex-basis: 70%;
    display: block !important;
  }

  a {
    font-size: inherit;
    color: #10387f;
    padding: 0;
  }
}
