$block-name: c-product-block;

.#{$block-name} {
  padding: 20px;
  display: block;
  &:hover {
    text-decoration: none;
    background-color: $color-gray;
  }

  .#{$block-name}-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: background-color 0.3s;

    .#{$block-name}-thumbnail {
      background-color: white;
      flex: 1 1 0;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      flex-basis: 100%;
      position: relative;
      img {
        background-color: white;
        border-radius: 3px;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .is-gradient {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          transparent 50%,
          rgba(0, 0, 0, 0.05)
        );
      }
    }

    .#{$block-name}-tail {
      flex: 1 1 0;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      flex-basis: 100%;

      .#{$block-name}-date-text {
        display: inline-block;
        padding: 0;
        line-height: 1.5;
        color: $color-black-light;
        font-size: 0.9rem;
      }

      .#{$block-name}-tail-title-text {
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 0;
      }
      .#{$block-name}-tail-content-text {
        font-size: 0.9rem;
      }
    }
  }
}

@include media(mobile-l) {
  .#{$block-name} {
    .#{$block-name}-container {
      .#{$block-name}-tail {
        position: relative;
        .#{$block-name}-tail-title-text {
          &:after {
            content: "\f105";
            font-family: ALicons;
            display: inline-block;
            margin-left: 7px;
            color: $color-grey;
          }
        }
      }
    }
  }
}
