.dl-icon-size1 {
  background: url("../../../assets/img/symbols-download-small.png") no-repeat;
  height: 36px;
  width: 27px;
}

.dl-icon-size2 {
  background: url("../../../assets/img/symbols-download-big.png") no-repeat;
  height: 42px;
  margin: auto;
  width: 32px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
  .dl-icon-size1 {
    -webkit-background-size: 450px;
    background: url("../../../assets/img/symbols-download-small@2x.png") no-repeat;
    background-size: 450px;
    height: 36px;
    width: 27px;
  }

  .dl-icon-size2 {
    -webkit-background-size: 450px;
    background: url("../../../assets/img/symbols-download-big@2x.png") no-repeat;
    background-size: 450px;
    margin: auto;
    height: 42px;
    width: 32px;
  }
}

.dl-icon-file {
  background-position: 0px 0px;
}

.dl-icon-doc,
.dl-icon-docx {
  background-position: -80px 0px;
}

.dl-icon-zip,
.dl-icon-zipx,
.dl-icon-rar,
.dl-icon-tar,
.dl-icon-gz {
  background-position: -160px 0px;
}

.dl-icon-pdf {
  background-position: -240px 0px;
}

.dl-icon-xls,
.dl-icon-xlsx {
  background-position: -320px 0px;
}

.dl-icon-pic,
.dl-icon-jpg,
.dl-icon-jpeg,
.dl-icon-png,
.dl-icon-gif,
.dl-icon-bmp {
  background-position: -400px 0px;
}