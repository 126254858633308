$margin: 0, 8, 16, 32;

@each $i in $margin {
  .m-#{$i} {
    margin: #{$i}px;
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .ml-#{$i} {
    margin-left: #{$i}px;
  }
}
