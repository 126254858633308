$block-name: c-vignette-block;

.c-vignette-block {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  align-content: flex-start;

  &.is-standard {
    &.is-image-top {
      flex-wrap: wrap;
      flex-direction: column;
      -ms-flex-direction: row;

      .c-vignette-block-body {
        flex-basis: 100%;
        order: 2;
      }

      .c-vignette-block-thumbnail {
        max-height: 320px;
        @extend .l-flex-item-100;
        order: 1;
        width: 100%;
      }
    }

    &.is-image-left {
      border-bottom-right-radius: 1.125rem;

      .c-vignette-block-body {
        order: 2;

        .c-vignette-block-body-wrapper {
          .c-vignette-block-button {
            flex-basis: 25%;
            text-align: right;

            .c-button {
              border-radius: 1.125rem 0px;
              margin: 2rem -1rem -1rem 0rem;
            }
          }
        }
      }

      .c-vignette-block-thumbnail {
        order: 1;
      }
    }

    &.is-image-right,
    &.is-image-top {
      border-bottom-left-radius: 1.125rem;

      .c-vignette-block-body {
        .c-vignette-block-body-wrapper {
          .c-vignette-block-button {
            text-align: left;

            .c-button {
              border-radius: 0px 1.125rem;
              margin: 2rem 0rem -1rem -1rem;
            }
          }
        }
      }
    }
  }




  &.is-slim {
    &.is-full-width {
      .c-vignette-block-body {
        .c-vignette-block-body-wrapper {
          flex-wrap: nowrap;

          .c-vignette-block-button {
            flex-basis: 25%;
            text-align: right;
          }
        }
      }
    }

    &.is-half-width {
      .c-vignette-block-body {
        .c-vignette-block-body-wrapper {
          text-align: center;
          align-content: center;
        }
      }
    }
  }

  &.is-white {
    background-color: white;
  }

  &.is-grey {
    background-color: $color-10-warm-grey;
  }

  &.is-brown {
    background-color: $color-20-warm-grey;
  }

  .c-vignette-block-thumbnail {
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 0 50%;
    min-height: 320px;
    object-fit: cover;
    height: 100%;

    img {
      max-height: 100%;
    }
  }
}

.c-vignette-block-body {
  display: flex;
  flex-direction: row;
  flex: 1 0 50%;
  word-break: break-word;
}

.c-vignette-block-body-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  padding: 16px;
  height: auto;
}

.c-vignette-block-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 100%;
  align-self: flex-start;

  .c-vignette-block-title {
    flex: 1 0 100%;
    align-self: center;

    h2 {
      margin: 0;
      font-weight: 300;
      margin-bottom: 1rem;
      padding-left: 0px;
      color: $color-blue;
    }
  }

  .c-vignette-block-text {
    flex: 1 0 100%;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.75;
    }
  }
}

.c-vignette-block-button {
  flex: 1 0 100%;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  align-self: flex-end;
  margin-bottom: 0;

  a {
    white-space: normal;
    line-height: 1.2;
    font-weight: 500;
    padding: 1rem 1.5rem;
    background-color: $color-grey;
    color: white;
    border: 1px solid $color-grey;
    background-color: $color-innovation;
    border: 1px solid $color-innovation;

    &:hover {
      background-color: $color-innovation-dark;
      border-color: $color-innovation-dark;
      color: #fff;
    }
  }
}

@include mediaSmallerThan(tablet) {
  .c-vignette-block {
    &.is-standard {

      &.is-image-top,
      &.is-image-left,
      &.is-image-right {
        flex-wrap: wrap;

        .c-vignette-block-body {
          flex-basis: 100%;
          order: 2;

          .c-vignette-block-body-wrapper {
            padding: 16px;
            min-height: 30vh;

            .c-vignette-block-content {
              .c-vignette-block-title {
                h2 {
                  font-size: 1.5rem;
                }
              }

              .c-vignette-block-text {
                p {
                  font-size: 1rem;
                }
              }
            }
          }
        }

        .c-vignette-block-thumbnail {
          min-height: 200px;
          flex-basis: 100%;
          order: 1;
        }
      }
    }
  }
}

@include mediaSmallerThan(mobile-l) {
  .c-vignette-block .c-vignette-block-body .c-vignette-block-body-wrapper {
    padding: 12px;
  }
}

@include mediaSmallerThan(mobile) {
  .c-vignette-block .c-vignette-block-body .c-vignette-block-body-wrapper {
    padding: 8px;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  .l-section.l-section-25 {
    max-width: 33%;
  }
}

.img-section {
  width: 100%;
}