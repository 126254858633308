.c-top-menu {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 0px 12px;
  background-color: $color-gray;
  position: relative;
  border-bottom-left-radius: 12px;

  &.loading {
    background-color: $color-gray;
    @include c-skeleton-horizontal-loading($color-gray);

    min-width: 400px;
  }

  .right-arc {
    position: absolute;
    top: 5px;
    left: -16px;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-position: bottom;
    transform: rotate(270deg);
  }

  .c-top-menu-link {
    align-items: center;
    color: $color-grey;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    padding: 8px;
    transition-duration: 175ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;

    &:hover {
      background-color: $color-white-beige;
      text-decoration: none;
    }

    &.current-language {
      background-color: $color-20-warm-grey;
      padding: 0 8px;

      .c-language-select {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }

    i {
      font-size: 16px;
      padding: 0 0 2px 8px;
      width: 1.125rem;
      text-align: right;
    }
  }

  .c-top-menu-link-active {
    box-shadow: 0 -2px 0 $color-blue inset;

    &:hover {
      text-decoration: none;
    }
  }

  select {
    margin: 0;
    width: auto;
    padding: 0;
  }
}