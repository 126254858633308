.c-view-more {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;

  a {
    align-items: center;
    color: $color-blue;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 1;
    margin-left: 8px;
    padding-right: 16px;
    text-align: right;
    text-decoration: none;
    transition: all 0.225s ease-in-out;

    &:hover {
      color: $color-blue-dark;
    }

    span {
      padding-right: 8px;
    }
  }
}