.c-search-bar-icon {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.2s ease;
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding: 10px;
    color: #4456af;

    &.is-hidden {
        display: none;
        opacity: 0;
    }
}

.c-search-page {
    cursor: inherit;

    .c-search-panel {
        padding: 0px;

        .autocomplete-container {
            position: relative;

            .c-search-panel-container {
                position: relative;
                display: flex;

                .c-search-bar {
                    border: 1px solid $color-70-warm-grey;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    font-weight: 100;
                    box-sizing: border-box;
                    padding: 10px 35px 10px 10px;
                    border-radius: 3px;
                    box-shadow: none;
                }

                .c-search-bar:focus {
                    outline: 0;
                }

                .c-search-icon {
                    @extend .c-search-bar-icon;
                }

                .c-clear-icon {
                    @extend .c-search-bar-icon;
                    cursor: pointer;
                }
            }
        }
    }

    .c-loading-spinner {
        display: none;
    }

    .c-filter-block {
        padding-left: 0px;
    }

    .c-category-results-container {
        padding-top: 0px;

        .c-document-list-item {
            padding-left: 1rem;

            .c-document-list-item-tail {
                display: flex;
                align-items: center;
                justify-content: center;

                .c-document-list-item-tail-container {
                    padding-right: 30px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-all;
                    /* Adds a hyphen where the word breaks, if supported (No Blink) */
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    hyphens: auto;
                }

                .c-document-list-item-view-container {
                    width: 100px;

                    .c-button {
                        padding: 0.5rem 2rem;

                        &.is-default {
                            background-color: $color-20-warm-grey;
                        }
                    }
                }
            }


        }
    }

    .l-show-more-btn {
        display: none;

        .c-button {
            background-color: $color-gray;
            border: 1px solid $color-gray;
            color: $color-grey;
            padding: 12px 32px 12px 32px;
            border-radius: 0 0 3px 3px;
            font-weight: 600;
            font-size: 14px;
        }
    }
}
