.c-contact-card {
  display: flex;
  flex-direction: column;

  .c-contact-card-name {
    padding-top: 0;
    margin-bottom: 5px;
  }
  .c-contact-card-position {
    padding-bottom: 15px;
  }
  .c-contact-card-address {
    padding: 0.5rem 0;
  }
  .c-contact-card-country {
    padding: 0.5rem 0;
  }
  .c-contact-card-city {
    padding: 0.5rem 0;
  }
  .c-contact-card-state {
    padding: 0.5rem 0;
  }
  .c-contact-card-phone {
    &:before {
      content: "\f095";
      font-family: ALicons;
      padding-right: 1rem;
    }
  }
  .c-contact-card-other-phone {
    &:before {
      content: "\f095";
      font-family: ALicons;
      padding-right: 1rem;
    }
  }
  .c-contact-card-fax {
    &:before {
      content: "\f1ac";
      font-family: ALicons;
      padding-right: 1rem;
    }
  }
  .c-contact-card-email {
    &:before {
      content: "\f0e0";
      font-family: ALicons;
      padding-right: 1rem;
    }
  }
  .c-contact-card-url {
    &:before {
      content: "\e60b";
      font-family: ALicons;
      padding-right: 1rem;
    }
  }
  .c-contact-card-description {
  }

  h4 {
    margin: 0;
    font-size: 1.1rem;
    padding: 0.7em 0 0.1em;
    font-weight: 700;
  }

  &.is-office {
    .c-contact-card-name {
      font-size: 1rem;
      line-height: 1.3;
      padding: 1em 0 0.25em;
      font-weight: 700;
      margin-top: 0;
    }
    .c-contact-card-record {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      .is-block {
        display: block;
      }
    }
  }
}
.phone-p {
    display: flex;
}
span.phone-number-span {
  padding-left: 5px;
}
.c-contact-group-title {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 400;
}

.c-contact-other-section {
  border-left: 1px solid #cdc9c5;
}
.c-l-contact-section {
  margin-top: 1rem ;
}
.contact-font{
  font-size: 32px;
  line-height: 42px;
  color:#3c3c3c;
}
.c-contact-default-address{
  margin-left: auto;
  margin-right: auto;
  max-width: 1248px;
}
@media screen and (max-width: 800px){
  .contact-font{
    font-size: 30px;
  }
}