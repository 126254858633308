$block-name: c-block-video;

.#{$block-name} {
  width: 100%;
  background-color: $color-5-warm-grey;
  padding: 2rem 0;
  .#{$block-name}-title {
  }

  .#{$block-name}-description {
    max-width: 58%;
  }

  .is-bound {
    width: 80%;
    margin: auto;
  }

  .#{$block-name}-thumbnail-container {
    position: relative;
    text-align: center;
    img {
      width: auto;
      max-width: 100%;
    }
  }

  .#{$block-name}-play-container {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 3px;
    background-color: $color-gray;
    top: calc(50% - 20px);
    left: calc(50% - 20px);

    &:hover {
      background-color: #F5F3F2;
    }

    .#{$block-name}-play-container-icon {
      position: relative;

      &:after {
        content: "\f105";
        font-family: ALicons;
        display: block;
        position: absolute;
        font-size: 48px;
        top: -1rem;
        left: 1rem;
        color: #3c3c3c;
      }
    }
  }
}


.c-block-video-player {
  width: 100%; 
  height: 0; 
  position: relative; 
  padding-bottom: 56.25%;
  overflow:hidden;
  & > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
