.c-block-benefit-list-title {
  font-size: 2rem;
  margin: 0 auto 2rem;
  max-width: 928px;
  text-align: center;
  color: $color-blue-dark;
}
.c-block-benefit-list {
  margin: 0 0 1rem 0;
  padding-left: 0;
  padding-right: 32px;
  .c-block-benefit-list-item {
    color: $color-110-warm-grey;
    display: block;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin: 8px 0;
    padding-left: 1.75rem;
    position: relative;
    text-align: left;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      font-family: "ALicons";
      content: "\e606";
      font-size: 0.4rem;
      left: 0;
      position: absolute;
      top: 5px;
      padding-right: 0;      
      background-color: $color-water;
      border-radius: 20px;
      width: 18px;
      height: 18px;
      color: $color-white;
      margin-right: 18px;
      text-align: center;
      padding-top: 0px;
      line-height: 1.2rem;
    }
  }

  @include mediaSmallerThan(tablet) {
    padding-left: 0;
    padding-right: 0;
  }
}
