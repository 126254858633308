.c-lc-item {
  &:hover {
    background-color: $color-block-hover-gray;
  }
  .c-lc-item-link {
    &:hover {
      text-decoration: none;
    }
    .c-lc-item-gradient {
      position: relative;
      border-radius: 3px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.05));
        display: block;
        clear: both;
        z-index: 2;
      }
    }
    .c-lc-item-gradient img {
      display: block;

      background-size: cover;
    }
    .c-lc-item-wrap {
      padding: 20px;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: hsla(27, 8%, 47%, 0);
        transition: background-color 0.2s;
        width: 20px;
        height: 20px;
      }
      .wrapperimgie {
        background-size: cover;
      }
      .c-lc-item-image {
        border-radius: 3px;
        display: block;
        max-height: 200px;
      }
      .c-lc-item-image-text {
        overflow: hidden;
        display: block;
        padding-top: 0.5rem;
        .c-lc-item-image-text-heading {
          margin: 5px 0;
          text-decoration: none;
        }
      }
    }
  }
}

.lc-container {
  margin: -16px;
}

@media screen and (max-width: $bkpt-mobile-landscape) {
  .filterbox-item {
    .c-lc-item {
      margin: 0 -16px;
    }
  }

  .filterbox-item {
    &.l-section-50 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .hidden {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-lc-item-gradient img {
    display: block;
  }
  .c-lc-item .c-lc-item-link .c-lc-item-wrap .c-lc-item-image {
    max-height: none;
  }
}
