.is-mobile {
  @include mediaGreaterThan(mobile-l) {
    display: none;
  }
}

.is-desktop {
  @include mediaSmallerThan(mobile-l) {
    display: none;
  }
}

.is-mobile-l {
  @include mediaGreaterThan(tablet) {
    display: none;
  }
}

.is-desktop-l {
  @include mediaSmallerThan(tablet-l) {
    display: none;
  }
}

.is-gray-20 {
  background-color: $color-10-warm-grey;
}

.has-padding-horizontal {
  padding-left: $default-padding;
  padding-right: $default-padding;
}

.no-margin-top {
  margin-top: 0;
}

.is-bound {
  max-width: $max-page-width;
  margin: auto;
  @media screen and (max-width: 1248px) {
    .is-bound {
      padding: 16px;
    }
  }
}

.hidden {
  display: none;
}

.is-hidden {
  visibility: hidden;
  height: 0;
}

.is-gray {
  background-color: $color-gray;
}

.is-upper-case {
  text-transform: uppercase;
}
.p-1 {
  padding: $default-padding;
}

.no-shadow {
  box-shadow: none;
}

.pr-8 {
  padding-right: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.select-pagelist {
  /* default needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/../assets/img/icons/arrow-down.png");
  background-color: white;
  background-position: calc(100% - 4px) center;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  border: solid 1px $color-40-warm-grey;
  border-radius: 3px;
  color: $color-grey;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  line-height: 1;

  /*added as per the requirement */
  padding: 0px 1rem;
  height: 3rem;
  border-color: $color-40-warm-grey;
}

.pagelist-title {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0.75rem 0;
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(67, 63, 61, 0.6);
  z-index: -100;
  opacity: 0;
  transition-duration: 275ms;
  transform: opacity;

  &.show {
    opacity: 1;
    z-index: 500;
  }
}

@media screen and (max-width: $bkpt-tablet-portrait-max) {
  .no-scroll {
    overflow: hidden;
  }
}

//OneTrust Cookies Consent Notice start for alfalaval.com

#onetrust-pc-sdk .ot-desc-cntr {
  outline: none;
}
#onetrust-consent-sdk #onetrust-pc-sdk h3,
#onetrust-consent-sdk #onetrust-pc-sdk h4,
#onetrust-consent-sdk #onetrust-pc-sdk h5,
#onetrust-consent-sdk #onetrust-pc-sdk h6,
#onetrust-consent-sdk #onetrust-pc-sdk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-ven-lst .ot-ven-opts p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-li-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-hdr span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-modal #modal-header,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-checkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-sel-blk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-lst-title span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .back-btn-handler p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-ven-name,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-ven-lst .consent-category,
#onetrust-consent-sdk
  #onetrust-pc-sdk
  .ot-leg-btn-container
  .ot-inactive-leg-btn,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-label-status,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #clear-filters-handler {
  line-height: 1.6;
}

#onetrust-consent-sdk #onetrust-pc-sdk .category-menu-switch-handler h3,
#onetrust-consent-sdk
  #onetrust-pc-sdk
  button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn),
#onetrust-consent-sdk
  #onetrust-pc-sdk
  .ot-leg-btn-container
  .ot-active-leg-btn {
  font-weight: 500;
}

#onetrust-banner-sdk h1,
#onetrust-banner-sdk h2,
#onetrust-banner-sdk h3,
#onetrust-banner-sdk h4,
#onetrust-banner-sdk h5,
#onetrust-banner-sdk h6,
#onetrust-pc-sdk h1,
#onetrust-pc-sdk h2,
#onetrust-pc-sdk h3,
#onetrust-pc-sdk h4,
#onetrust-pc-sdk h5,
#onetrust-pc-sdk h6,
#ot-sdk-cookie-policy h1,
#ot-sdk-cookie-policy h2,
#ot-sdk-cookie-policy h3,
#ot-sdk-cookie-policy h4,
#ot-sdk-cookie-policy h5,
#ot-sdk-cookie-policy h6 {
  font-weight: 500;
}
#onetrust-pc-sdk .ot-pc-footer button {
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.cookie_button {
  background-color: #007fc8;
    border: 1px solid #007fc8 !important;
    font-size: 16px !important;
    padding: 16px 24px !important;
    color: #fff !important;
}
.cookie_button:hover {
  background-color: #007fc8 !important;
  border: 1px solid #007fc8 !important;
}
.cookie_button:focus {
  outline: none;
  box-shadow: none;
}

.al-break-word {
  word-break: break-word;
}

