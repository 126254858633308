@mixin c-button-base {
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1rem;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition-duration: $durationDesktopShort;
    transition-timing-function: $transitionStandard;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: pre-line;
  }
  