.l-content-area {
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: flex-start;
  padding: 16px 0;
  position: relative;
  width: 100%;
}

@mixin content-direction($direction) {
  @if $direction == col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  } @else if($direction == row) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.l-content {
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  &.light-grey {
    background-color: $color-gray;
  }

  &.light-brown {
    background-color: $color-10-warm-grey;
  }

  &.l-content-no-margin {
    margin: 0;
  }
}

.l-content-gray {
  background-color: $color-gray;
  margin-top: 32px;
  margin-bottom: 32px;
}

.l-content-direction-row {
  @include content-direction(row);
}

.l-content-direction-col {
  @include content-direction(col);
}

.l-content-without-padding > .l-section-padding {
  padding: 0px !important;
}
