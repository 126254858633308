.l-contact-us-container {
  margin: 0 auto;
  max-width: $max-page-width;
  padding-bottom: 48px;
  position: relative;
  width: 100%;

  .c-close-button {
    margin:0px;
  }

  .c-select {
    label {
      color: $color-grey;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }

    .c-button {
      background-color: white;
      border: 1px solid $color-70-warm-grey;
      border-radius: 3px;
      box-sizing: border-box;
      color: $color-70-warm-grey;
      display: flex;
      font-size: 1rem;
      font-weight: 400;
      justify-content: space-between;
      min-width: 100%;
      position: relative;
      transition: border-color 0.1s ease-out;
      width: -moz-fit-content; /* Fix for FF 61+ */
      width: fit-content;

      .c-select-wrapper {
        align-items: center;
        display: flex;
        height: 32px;
        justify-content: space-between;
        padding: 0 8px;
        width: 100%;
      }

      .c-select-list {
        background-color: white;
        border-color: $color-70-warm-grey;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
        left: 0;
        margin-top: 1px;
        min-width: 100%;
        padding-bottom: 10px;
        padding-top: 5px;
        position: absolute;
        top: 101%;
        width: -moz-fit-content; /* Fix for FF 61+ */
        width: fit-content;
        z-index: 12;
      }
    }
  }
}

.l-contact-us-select {
  background-color: white;
  border: solid 1px $color-40-warm-grey;
  border-radius: 3px;
  color: $color-grey;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  padding: 0 8px;
}

.l-contact-us-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.l-contact-us-column {
  flex: 1 1 auto;
  &:nth-child(1) {
    flex-basis: 27%;
    flex-grow: 0;
    padding: 0 40px 20px 0;
  }

  label {
    display: inline-block;
    font-size: 0.85rem;
    margin-bottom: 5px;
  }
}

.l-contact-us-form-wrapper {
  background-color: $color-10-warm-grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.l-contact-us-column {
  flex: 1 1 auto;
  &:nth-child(1) {
    flex-basis: 27%;
    flex-grow: 0;
    padding: 0 40px 20px 0;
  }

  label {
    display: inline-block;
    font-size: 0.85rem;
    margin-bottom: 5px;
  }
}
