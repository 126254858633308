.c-submenu-item {
  padding: 0.375em 0.875em;
  margin-bottom: 0.5rem;
  margin-right:2px;
  text-decoration: none;  
  display: inline-block;
  line-height: 1.5;
  color: $color-black;
  background-color: #F1EFED ;
  &.active {
    color: #fff;
    background-color: #23337E;
    text-decoration: none;
  }
  &:hover {
    color: #fff;
    background-color: #23337E;
    text-decoration: none;
  }
}
