.svg-icon {
  align-items: center;
  display: flex;

  svg {
    height: 18px;
    width: 18px;

    g {
      fill: $color-blue;

      polygon {
        fill: $color-blue;
      }
    }
  }

  &.grey {
    svg {
      fill: $color-grey;

      g {
        fill: $color-grey;

        polygon {
          fill: $color-grey;
        }
      }
    }
  }

  &.dark {
    svg {
      fill: $color-110-warm-grey;

      g {
        fill: $color-110-warm-grey;

        polygon {
          fill: $color-110-warm-grey;
        }
      }
    }
  }

  &.big {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  &.small {
    svg {
      height: 10px;
      width: 10px;
    }
  }
}
.al-icon-blue{
  font-size: 36px;
  color: #11387f;
}

