.l-page {
  background-color: white;

  .l-page-center {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-page-width;
    padding: 16px 0 16px 0;

    &.news-article-padding {
      padding: 0px;
    }
  }

  .l-rounded-image {
    width: 100%;
    padding-bottom: 100%;
    background: no-repeat 50%;
    background-size: cover;
    border-radius: 50%;
  }
}

.l-page-navigation {
  display: flex;
  align-items: center;
  height: 48px;
  background-color: $color-white-beige;
  width: 100%;
  justify-content: center;
}