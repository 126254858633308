.l-standard-page-image-banner {
  background: no-repeat 50%;
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.l-standard-page-fullwidth-banner {
  min-height: 400px;

  .c-image-file {
    background: no-repeat 50%;
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
    flex-basis: 100%;
  }

  .l-section.l-section-20.l-section-padding,
  .l-section.l-section-25.l-section-padding,
  .l-section.l-section-33.l-section-padding,
  .l-section.l-section-50.l-section-padding,
  .l-section.l-section-66.l-section-padding,
  .l-section.l-section-75.l-section-padding,
  .l-section.l-section-100.l-section-padding {
    padding: 0px;
  }

  .c-vignette-block-body {
    width: 100%;
  }

  .compat-object-fit {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &>.c-image-file {
      opacity: 0;
    }
  }
}

.wysiwyg .standard-page-title {
  padding: 0 16px;
  font-weight: 300;
}