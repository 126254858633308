$block-name: c-block-title-banner;
$newblock-name: c-block-title-banner-new;

.#{$block-name} {
  width: 100%;
  position: relative;
  background: no-repeat 50%;
  background-size: cover;
  height: 40vh;
  min-height: 200px;
  max-height: 480px;

  &.has-no-image {
    background-color: $color-white;
    height: auto;
    min-height: 200px;
    .#{$block-name}-card {
      position: relative;
    }
  }

  *::-ms-backdrop,
  .#{$block-name}-card {
    bottom: -1px;
  }

  .#{$block-name}-card {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 1.5rem;
    text-align: center;

    .#{$block-name}-card-wrapper {
      max-width: $max-inner-width;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: white;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      padding: 2rem 4rem;

      .#{$block-name}-title-container {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        text-align: center;
      }

      .#{$block-name}-text-container {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        font-size: 1rem;

        h4 {
          margin: 0;
        }
      }
    }
  }
}
@include mediaSmallerThan(tablet) {
  .#{$block-name} {
    .#{$block-name}-card {
      h1 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1rem;
      }
      .#{$block-name}-card-wrapper {
        padding: 1rem;
      }
      .c-divider {
        margin: calc($default-margin/2) auto;
      }
    }
  }
}

.#{$newblock-name} {
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 0 50%;
  height: auto;
  min-height: 320px;

  img {
    aspect-ratio: 16/9;
  }

  @media screen and (max-width: $bkpt-tablet) {
    min-height: 190px;
  }
}
