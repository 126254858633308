.c-related-card {
  box-sizing: border-box;
  display: block;
  margin: 16px;
  position: relative;

  &:hover {
    background-color: #f1efed;
    text-decoration: none;
  }

  h4 {
    color: $color-110-warm-grey;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-right: 32px;
    text-align: center;
  }
  p {
    color: $color-110-warm-grey;
    font-size: 14px;
    line-height: 1.71;
  }

  a:hover {
    text-decoration: none;
  }

  &.is-style-1 {
    .c-related-card-container {
      background-color: #f1efed;
      border-radius: 3px;
      min-width: 260px;
      padding: 0;
      text-align: center;

      img {
        min-height: 100%;
        max-width: 100%;
        mix-blend-mode: multiply;
        object-fit: contain;
        height: inherit;
      }

      h4 {
        margin-top: 0;
        padding-top: 0;
      }
    }
    .c-related-card-bullets-container {
      background-color: $color-5-warm-grey;
      height: 100%;
      left: 0;
      overflow-y: auto;
      position: absolute;
      top: 0;
      width: 100%;

      h4 {
        padding-left: 1rem;
        padding-right: 32px;
        margin-top: 0;
        padding-top: 8px;
      }

      ul {
        margin: 0 0;
        padding-left: 1rem;

        li {
          color: $color-grey;
          font-size: 16px;
          font-weight: 300;
          position: relative;
          margin-left: 12px;
        }
      }
      li:before {
        color: $color-grey;
        content: '\00b7';
        font-size: 50px;
        line-height: 25px;
        vertical-align: middle;
        position: absolute;
        left: -16px;
      }
    }
  }

  &.is-style-2 {
    .c-related-card-container {
      background-color: transparent;
      border-radius: 3px;
      min-width: 260px;
      padding: 0;
      text-align: center;
      img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        mix-blend-mode: multiply;
      }
    }
  }

  &.is-style-2,
  &.is-style-3 {
    .c-related-card-text {
      text-align: center;
      h4 {
        color: $color-110-warm-grey;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }

  &.is-style-3 {
    &:hover .c-related-card-container {
      background-color: $color-5-warm-grey;
    }

    .c-related-card-container {
      background-color: transparent;
      border-bottom: 1px solid $color-grey;
      min-width: 260px;
      padding: 0;
      text-align: center;
      &:hover {
        background-color: $color-5-warm-grey;
      }
      img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        mix-blend-mode: multiply;
      }
      .c-related-card-image {
        max-width: 250px !important;
      }
    }
  }

  .c-related-card-container {
    padding: 1rem 1rem 1rem 1rem;
    .c-related-card-thumbnail-container {
      background-color: white;
      position: relative;

      .is-gradient {
        background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.05));
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .c-related-card-text {
    word-break: break-word;
  }

  .c-related-card-more-btn {
    align-items: center;
    color: $color-grey;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    &:hover {
      color: $color-110-warm-grey;
    }
  }

  .c-related-card-image {
    display: block;
    margin: auto;
    background-color: white;
    width: 100%;
    height: 200px;
    transition-property: transform;
    transition-duration: 175ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    mix-blend-mode: multiply;
  }
}
