.c-subnavigation {
  &.primary {
    .c-subnavigation-header {
      background-color: $color-blue;
      border-color: $color-blue;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0;
    }

    .c-subnavigation-body {
      background-color: $color-gray;
    }
  }
  .c-subnavigation-header {
    border-top-right-radius: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
  }
  .c-subnavigation-body {
    .wysiwyg {
      margin-left: 0;
      padding-left: 0;
    }
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
