.c-slider {
  position: relative;

  .c-slider-nav {
    width: 100%;
    text-align: center;
  }

  .c-slider-nav-container {
    display: inline-block;
    margin: auto;

    .c-slider-nav-header {
      cursor: pointer;
      display: inline-block;
      margin: 0;
      min-width: 100px;
      padding: 10px;
      position: relative;
      color: $color-grey;
      font-size: 0.9em;
    }

    .c-slider-progress {
      &:hover {
        background-color: #717171;
      }
    }

    .c-slider-dot {
      &:hover {
        background-color: #717171;
      }
    }
  }

  .c-slider-slide {
    position: relative;

    &.is-visible {
      display: block;
    }

    &.is-hidden {
      display: none;
    }

    &:before {
      content: '';
      top: 0;
      right: -0px;
      bottom: 0;
      left: -0px;
      display: block;
      clear: both;
      z-index: 2;
      box-shadow: inset 0 -18px 12px -20px rgba(0, 0, 0, 0.5);
    }
  }

  .is-fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes scroll {
    from {
      transform: translate(0px);
    }

    to {
      transform: translate(-100%);
    }
  }
}

.c-slider-swiper {
  background-color: transparent;
  width: 100%;

  .c-slider-nav {
    text-align: center;
    position: absolute;
    z-index: 10;
  }

  .swiper-pagination-bullet {
    background: $color-70-warm-grey;
  }

  .swiper-pagination-bullet-active {
    background: white;
  }
}

.c-story-swiper {
  width: 100%;

  &.c-story-swiper {
    margin-bottom: 40px;
  }

  .c-slider-nav {
    text-align: center;
    padding: 10px;
  }

  .swiper-slide {
    width: auto;
    max-width: 588px;
    min-width: 588px;
  }

  .swiper-pagination-bullet {
    background: $color-70-warm-grey;
  }

  .swiper-pagination-bullet-active {
    background: $color-grey;
  }

  .swiper-pagination-bullet {
    width: 13px;
    height: 13px;
  }
}

@media screen and (max-width: 800px) {
  .c-story-swiper {
    &.c-story-swiper {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 800px) {
  .c-story-swiper {
    &.c-story-swiper {
      margin-bottom: 0;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 30%;
    }

    .swiper-slide {
      max-width: 400px;
      min-width: 310px;
    }
  }

  .story-swiper-gradient {
    height: 47%;
  }
}

@media screen and (max-width: 480px) {
  .c-story-swiper {
    .c-banner {
      margin-left: -10px;

      .c-banner-wrapper {
        width: 100vw;
      }
    }

    .swiper-slide {
      margin-right: 0px !important;
      min-width: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 30%;
    }
  }

  .story-slider-block {
    .c-story-swiper .c-banner .c-banner-wrapper {
      width: unset;
    }

		.c-story-swiper .c-banner {
			margin: 10px 10px 10px -10px;
			min-width: unset;
		}

		// Adds gradient effect to left and right side of image for mobile screen sizes
		.swiper-slide-active {
			.c-banner-image-container img {
				mask-image: linear-gradient( 90deg, 
      	rgba(255, 255, 255, 0.2) 0%, 
      	rgba(255, 255, 255, 1) 20%, 
      	rgba(255, 255, 255, 1) 80%, 
      	rgba(255, 255, 255, 0.2) 100% );
			}
		}
  }

  .story-swiper-gradient {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .c-story-swiper {
    .swiper-button-next,
    .swiper-button-prev {
      top: 20%;
    }
  }
}

.c-slider-swiper-nav-container {
  max-width: 1350px;

  .swiper-button-prev {
    background-image: none;
    transform: rotate(90deg);
    padding: 1.5rem 0px;
    font-family: 'ALicons';
    color: #fff;
    font-size: 1.375rem;
    text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.25), 1px -1px 1px rgba(128, 128, 128, 0.25),
      -1px -1px 1px rgba(128, 128, 128, 0.25), -1px 1px 1px rgba(128, 128, 128, 0.25);
    width: 4rem;
    height: 4rem;
    text-align: center;

    &:after {
      content: '\e927';
    }

    &:hover {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .swiper-button-next {
    background-image: none;
    transform: rotate(270deg);
    padding: 1.5rem 0px;
    font-family: 'ALicons';
    color: #fff;
    font-size: 1.375rem;
    text-shadow: 1px 1px 1px rgba(128, 128, 128, 0.25), 1px -1px 1px rgba(128, 128, 128, 0.25),
      -1px -1px 1px rgba(128, 128, 128, 0.25), -1px 1px 1px rgba(128, 128, 128, 0.25);
    width: 4rem;
    height: 4rem;
    text-align: center;

    &:after {
      content: '\e927';
    }

    &:hover {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.story-slider-block {
  .swiper-button-blue {
    color: $color-blue;
  }
}

.story-swiper-gradient {
  position: absolute;
  top: 7.5px;
  width: 100%;
  z-index: 5;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.8) 0%,
    transparent 20%,
    transparent 80%,
    rgba(255, 255, 255, 0.8) 100%
  );
}

@media screen and (min-width: 801px) {
  .story-swiper-gradient {
    height: 84%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, transparent 6%, transparent 94%, rgba(255, 255, 255, 0.8) 100%);
  }
}