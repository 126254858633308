.c-event-item {
  margin-bottom: 25px;

  .c-event-item-heading {
    font-size: 1.1em;
    line-height: 1.3;
    font-weight: 700;
    margin: 0;
    color: $color-black;
  }
  .c-event-item-date {
    font-size: 0.85em;
    line-height: 1.5;
    font-weight: 400;
    color: $color-70-warm-grey;
    margin-bottom: 5px;
  }
  .c-event-item-location-container {
  }
  .c-event-item-marker-icon {
  }
  .c-event-item-marker-description {
  }
  .c-event-item-description {
  }
}
