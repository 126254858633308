.l-follow-icons-container {
  color: $color-grey;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-grow: 0;

  .l-follow-icons-container-links {
    display: inline-flex;
    flex-direction: row;
  }

  @include media(mobile) {
    padding: 30px 20px 20px 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  @include media(mobile-l) {
    padding: 30px 20px 20px 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  @include media(tablet) {
    padding: 30px 20px 20px 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .share {
    font-size: 14px;
    font-weight: 400;
    color: $color-grey;
  }

  .follow {
    font-size: 14px;
    font-weight: 400;
    color: $color-white;
    width: 100%;
  }

  *::-ms-backdrop,
  .follow {
    width: auto;
  }
}

.l-follow-icons-container-links:hover .c-follow-link a {
  opacity: 0.5;
}

.l-follow-icons-container-links:hover .c-follow-link a:hover {
  opacity: 1;
}
