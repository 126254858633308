.c-list-link,
.list-link {
  margin-top: 1rem;
  position: relative;
  padding-left: 1.2rem;
  display: inline-block;
  color: $color-font-blue;
  &:hover {
    color: black;
    text-decoration: none;
  }
  &:visited {
    color: $color-font-blue;
  }
  &:before {
    content: "\f105";
    font-family: ALicons;
    display: block;
    position: absolute;
    left: 0;
    color: $color-font-blue;
  }
}
.c-link-list {
  margin: 0 !important;
  li {
    &:before {
      content: "\f105" !important;
      font-family: ALicons !important;
      display: block !important;
      position: absolute !important;
      line-height: 26px !important;
      font-size: 24px !important;
      left: 0 !important;
      color: $color-font-blue !important;
    }
  }
  a:visited {
    color: #4456af;
  }
}
