* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

fieldset {
  border: none;
  padding: unset;
  margin: unset;
}

img {
  width: 100%;
  height: auto;
  overflow: hidden;
}
iframe {
  max-width: 100%;
}

hr {
  background-color: #cdc9c5;
  clear: both;
  height: 1px;
  border: none;
  margin: 0;
}

b {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
