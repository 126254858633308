.c-service-portfolio {
    justify-content: center;
    flex-direction: row;
    display: flex;
    flex: 0 1 1264px;
        .c-service-portfolio-content {
            flex: 0 1 25%;
            order: 0;
            display: flex;
            
            .c-service-portfolio-category {
                margin-bottom: 2rem;
                p {
                    &.title {
                        color: $color-110-warm-grey;
                        font-size: 1.25rem;
                        border-bottom: 2px solid $color-110-warm-grey;
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                        font-weight: 300;
                    }
                    a{
                        font-weight: 400;
                        font-size: 1rem;
                        color: $color-font-blue;
                        cursor: pointer;
                      }
                }  
                @media screen and (max-width: 580px) { 
                    margin-bottom: 1rem;
                }
            }
            &.left {
                padding: 1.5rem 1rem 1.5rem 0;
                flex-direction: column;
                @media screen and (max-width: 580px) {
                    order:3;
                    flex-direction: column-reverse;
                    
                }
            }
            &.right {
                padding: 1.5rem 0px 1.5rem 1rem;
                flex-direction: column;
                @media screen and (max-width: 580px) {
                    order:2;
                    flex-direction: column;
                }
            }
                  
            @media screen and (max-width: 580px) {
                flex: 0 1 100%;
                align-self: stretch; 
                padding: 0 !important;                    
            }
        }
    
        .c-service-portfolio-image {
            flex: 0 1 50%;
            order: 0;
            padding: 1.5rem;
            
              @media screen and (max-width: 580px) {
                flex: 0 1 100%;
                align-self: stretch;
                order:1;
                padding: 0; 
                margin-bottom: 0.5rem; 
              }
        }
        @media screen and (max-width: 580px) { 
            flex-direction: column;
        }
    }
