@import url("@assets/fonts/roboto/font_roboto.css");

* {
  -webkit-font-smoothing: antialiased;
}

$font-weights: (
  "light": 300,
  "medium": 400,
  "semibold": 500,
  "bold": 700
);

$font-sizes: (
  "xs": 12px,
  "sm": 14px,
  "md": 16px,
  "lg": 18px,
  "x-lg": 20px,
  "xx-lg": 24px,
  "jumbo": 32px,
  "Giant": 40px,
  "Mega": 42px,
  "Ultra": 48px,
);

$line-heights: (
  "120": 1.2,
  "140": 1.4,
  "150": 1.5,
  "117": 1.17,
  "175": 1.75,
);

// Generate font classes for each font weight, size and line height
@each $weight-name,
$weight in $font-weights {

  @each $size-name,
  $size in $font-sizes {

    @each $line-height-name,
    $line-height in $line-heights {
      .font-#{$weight-name}-#{$size-name}-lh-#{$line-height-name} {
        font-weight: $weight;
        font-size: $size;
        line-height: $line-height;
      }
    }
  }
}

body {
  font-family: "Roboto", sans-serif;
  background-color: $color-white;
  color: $color-black;
  font-size: 16px;
}

button {
  font-family: "Roboto", sans-serif;
}

a {
  color: $color-black;
  text-decoration: none;
}

a.white-color {
  color: $color-white;
}

a:hover {
  text-decoration: none;
}

p {
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: $color-black;
}

h4 {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.7;
  margin: 16px 0 0 0;
}

h3 {
  color: $color-110-warm-grey;
  font-size: 24px;
  font-weight: 300;
}

h2 {
  color: $color-110-warm-grey;
  font-size: 32px;
  font-weight: 300;
  padding: .75rem 0 .75rem 0;
}

h1 {
  color: $color-blue;
  font-size: 48px;
  font-weight: 300;
  margin: 0;
}

a,
a:visited {
  text-decoration: none;
  font-size: 16px;
  line-height: 1.75;
  color: $color-black;

  &:hover {
    text-decoration: underline;
  }
}

label,
legend {
  color: $color-black-light;
}

label {
  &.c-radio-button-container {
    color: $color-black;
  }
}

table {
  line-height: 1.75;
}