.c-block {
  .wysiwyg {
    padding-top: 0px;
  }
}

.c-block-title {
  color: $color-110-warm-grey;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  margin: 0;
  padding: 0;
}
