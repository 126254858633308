.c-hero-banner2 {
  height: 480px;
  position: relative;
  overflow: hidden;

  .is-dark {
    color: $color-110-warm-grey;
  }
}

.swiper-pagination-bullet {
  background: $color-5-warm-grey  !important;
  opacity: 1 !important;
  width: 1rem;
  height: 1rem;
  border: 1px solid rgba(132, 119, 112, .75);
  margin: 0 0.5rem !important;
}

.swiper-pagination-bullet-active {
  background: $color-innovation  !important;
}

.c-hero-banner2-image {
  background: no-repeat 50%;
  background-size: cover;
  height: 480px;
  max-height: 480px;
  min-height: 100%;
  object-fit: cover;

  img {
    height: 100%;
  }

  &.is-mobile {
    display: none;
  }
}

.c-hero-banner2-content {
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  max-width: inherit;
  margin-left: auto;
  margin-right: auto;

  &.overlay-0 {
    background: rgba(0, 0, 0, 0);
  }

  &.overlay-20 {
    background: rgba(0, 0, 0, 0.2);
  }

  &.overlay-40 {
    background: rgba(0, 0, 0, 0.4);
  }

  &.overlay-60 {
    background: rgba(0, 0, 0, 0.6);
  }

  &.overlay-80 {
    background: rgba(0, 0, 0, 0.75);
  }
}

.c-hero-banner2-card {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;

  &.c-hero-banner2-card-left {
    left: 20px;
  }

  &.c-hero-banner2-card-right {
    right: 20px;
  }

  &.c-hero-banner2-card-center {
    text-align: center;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);

    p {
      text-align: center;
    }

    right: calc(50% - 200px);
  }

  &.c-hero-banner2-card-primary {
    background-color: $color-blue;

    .c-hero-banner2-card-header {
      color: $color-white;
    }

    .c-hero-banner2-card-subtitle {
      color: $color-white;
    }

    .c-hero-banner2-card-paragraph {
      color: $color-white;
    }
  }

  &.c-hero-banner2-card-light {
    background-color: $color-gray;

    .c-hero-banner2-card-header {
      color: $color-black;
    }

    .c-hero-banner2-card-subtitle {
      color: $color-black;
    }

    .c-hero-banner2-card-paragraph {
      color: $color-black;
    }
  }
}

.c-hero-banner2-card-header {
  color: white;
  font-size: 48px;
  padding: 8px;
  text-align: center;
}

.c-hero-banner2-card-subtitle {
  color: white;
  margin: 0;
  padding: 5px 35px;
  font-size: 1.6rem;
}

.c-hero-banner2-card-paragraph {
  color: white;
  font-size: 20px;
  max-width: 672px;
  padding: 0 8px;
  text-align: center;
}

.c-hero-banner2-card-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  &.button-container-left {
    justify-content: flex-start;
  }

  &.button-container-right {
    justify-content: flex-end;
  }
}

a.c-hero-banner2-card-button {
  background-color: $color-blue;
  border-radius: 3px;
  color: white;
  font-size: 16px;
  padding: 8px 16px;

  &:hover {
    text-decoration: none;
  }

  &.button-primary {
    background-color: $color-blue;
    color: white;
    border: 1px solid $color-blue;
  }

  &.button-light {
    background-color: white;
    color: $color-blue;
    border: 1px solid $color-20-warm-grey;
  }
}

@include mediaSmallerThan(tablet) {
  .c-hero-banner2 {
    text-align: center;
  }

  .c-hero-banner2-card-button-container {
    display: block;
    margin-top: 24px;
  }

  .c-hero-banner2-card {
    display: block;
  }

  .c-hero-banner {

    h1,
    h2,
    p {
      text-align: center !important;
    }

    .c-hero-banner-card {
      position: absolute;
      width: 100% !important;
      padding-bottom: 32px;
    }

    .hero-img {
      object-fit: cover;
    }
  }

  .c-hero-banner2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    overflow: hidden;

    .c-hero-banner2-image {
      flex: 1 1 100%;

      &.is-mobile {
        display: inherit;
      }

      &.is-desktop {
        display: none;
      }
    }

    .c-hero-banner2-card-header {
      font-size: 36px;
      padding: 32px 24px 8px;
    }

    .c-hero-banner2-card-paragraph {
      padding: 8px 48px;
      font-size: 16px;
    }
  }
}

@include mediaSmallerThan(mobile-l) {

  .swiper-pagination-bullets,
  .hide-in-mobile {
    display: none;
  }

  .c-hero-banner2-card-button-container {
    margin-top: 16px;
  }

  .c-hero-banner2-card-header {
    font-size: 28px !important;
    padding: 32px 24px 8px;
  }
}