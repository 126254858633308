.c-contact-us-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: $max-page-width;
  padding: 0px 0px;
  position: relative;
  width: 100%;

  h3 {
    color: $color-110-warm-grey;
    font-size: 32px;
    margin-bottom: 8px;
  }

  input {
    background-color: white;
    border: solid 1px $color-40-warm-grey;
    border-radius: 3px;
    color: $color-grey;
    font-size: 16px;
    font-weight: 500;
    height: 32px;
    padding: 0 8px;
    width: 100%;
  }

  select {
    /* needed */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/../assets/img/icons/arrow-down.png");
    background-color: white;
    background-size: 18px;
    background-position: calc(100% - 4px) center;
    background-repeat: no-repeat;
    background-size: 18px;
    border: solid 1px $color-40-warm-grey;
    border-radius: 3px;
    color: $color-grey;
    font-size: 16px;
    font-weight: 500;
    height: 32px;
    padding: 0 8px;
    width: 100%;
  }

  select::-ms-expand {
    display: none;
  }

  legend {
    margin-bottom: 10px;
  }

  textarea {
    border: 1px solid $color-40-warm-grey;
    border-radius: 4px;
    color: $color-grey;
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
    width: 100%;
  }

  .c-common-input {
    margin-bottom: 8px;
    padding: 10px 5px;
    border-radius: 4px;
  }

  .c-radio-button {

    /* The container */
    .c-radio-button-container {
      color: $color-grey;
      cursor: pointer;
      display: block;
      font-size: 1rem;
      font-size: 16px;
      font-weight: 500;
      padding-left: 35px;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      /* Hide the browser's default radio button */
      input {
        opacity: 0;
        position: absolute;
      }

      /* Create a custom radio button */
      .checkmark {
        background-color: white;
        border: 1px solid $color-40-warm-grey;
        border-radius: 50%;
        height: 18.28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 18.28px;
      }

      /* Style the indicator (dot/circle) */
      .checkmark:after {
        background-color: white;
        border: 5px solid $color-grey;
        border-radius: 50%;
        bottom: 0px;
        left: 0px;
        right: 0px;
        top: 0px;
      }
    }
  }
}

.c-contact-us-form-file-label {
  color: $color-70-warm-grey;
  font-size: 14px;
  font-weight: 500;
}

.c-contact-us-form-label {
  color: $color-grey;
  font-size: 14px;
  line-height: 12px;
  font-weight: bold;
  color: #545151;

  &.light {
    color: $color-70-warm-grey;
  }
}

.error-border {
  border-color: $color-error;
}

.c-contact-us-form-success-message {
  background-color: $color-gray;
  font-size: 1rem;
  margin: 2rem 0;
  padding: 1.5rem 1.3rem;
}

.c-contact-us-form-spinner {
  display: flex;
  height: 100px;
  justify-content: center;
  margin: 20px 0;
  width: 100%;

  img {
    height: 100%;
    width: auto;
  }
}

.c-contact-us-form-error-message {
  font-size: 1rem;
  color: #d81e05;
  padding: 1.5rem 1.3rem;
}

.c-contact-us-form-column {
  &.form-gdpr-check {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    input[type="checkbox"] {
      align-self: flex-start;
      width: 1.125rem;
      height: 1.125rem;
      box-shadow: none;
    }

    label {
      flex: 0 1 92%;

      a {
        color: $color-blue;
      }
    }
  }
}

.c-contact-us-form-button {
  text-align: center;
  margin-top: 1em;
}

@include mediaSmallerThan(tablet) {
  .c-contact-us-form-wrapper {
    display: block;
    flex-direction: column;

    fieldset {
      padding: 0;
    }
  }

  .c-button {
    &.is-100-mobile {
      width: 100%;
    }
  }

  .c-contact-us-form-column {
    padding-right: 0;
  }

  .c-contact-us-form-inner {
    flex-direction: column;
  }

  .c-contact-us-form-inner-child {
    width: 100%;
  }
}

.c-contact-us-form-response-wrapper {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }

  p {
    text-align: center;
  }
}