input,
textarea,
select {
  width: 100%;
  border-radius: 3px;
  border: 1px solid $color-gray-border;
  padding: 4px 8px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  background-color: $color-white;
  margin: 4px 0;
  color: $color-black;

  &:focus {
    border-color: $color-black;
    box-shadow: 0 0 4px $color-gray-dark;
  }
}

input {

  &[type="checkbox"],
  &[type="radio"] {
    width: auto;
  }

  &[placeholder] {
    color: $color-black-light;
  }
}

@media screen and (max-width: $bkpt-mobile-landscape) {
  input {
    width: 100%;
  }
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
}

input:-webkit-autofill {
  border: 1px solid rgb(0, 5, 0);
  border-style: solid;
  -webkit-text-fill-color: $color-110-warm-grey;
  transition: background-color 5000s ease-in-out 0s;
}