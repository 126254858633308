.c-radio-button {

  /* The container */
  .c-radio-button-container {
    display: flex;
    gap: 16px;

    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Create a custom radio button */
    .checkmark {
      appearance: none;
      display: grid;
      place-content: center;
      top: 0;
      left: 0;
      margin: 0;
      height: 18.28px;
      width: 18.28px;
      background-color: white;
      border-radius: 50%;
      border: 1px solid $color-gray-border;
    }

    /* Create and style the indicator (dot/circle) */
    .checkmark:after {
      content: "";
      position: relative;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: $color-blue;
      transform: scale(0);
      place-self: center;
    }

    .checkmark:checked:after {
      transform: scale(1);

    }
  }
}