html {
  &:not(.campaign) {
    .EPiServerForms {
      background-color: $color-gray;
      padding: 20px 40px;
    }

    .EPiServerForms h2.Form__Title {
      font-family: "Roboto", sans-serif;
      font-size: 1.5rem;
      padding: 0 0 20px 0;
      font-weight: 300;
      margin: 0;
    }

    .EPiServerForms .Form__Success__Message a {
      color: #4456af;
    }

    .EPiServerForms .Form__Success__Message a:hover {
      color: #3c3c3c;
      text-decoration: underline;
    }

    .EPiServerForms .Form__Description,
    .EPiServerForms .Form__Status,
    .EPiServerForms .Form__Status__Message,
    .EPiServerForms .Form__Status p {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.7;
      margin-bottom: 0.71rem;
    }

    .EPiServerForms .Form__Success__Message,
    .EPiServerForms .Form__Readonly__Message,
    .EPiServerForms .Form__Warning__Message,
    .EPiServerForms .Form__Warning,
    .EPiServerForms .Warning {
      background-color: transparent !important;
    }

    .EPiServerForms .Form__Warning__Message,
    .EPiServerForms .Form__Warning,
    .EPiServerForms .Warning {
      color: #f44336 !important;
    }

    .EPiServerForms .Form__Readonly__Message {
      color: #ff9800 !important;
    }

    .EPiServerForms .Form__Description,
    .EPiServerForms .Form__Success__Message,
    .EPiServerForms .Form__Success__Message p {
      font-family: "Roboto", sans-serif;
      font-size: 1.28rem;
      font-weight: 300;
    }

    .EPiServerForms .Form__Element {
      font-size: 1.01rem;
      margin: 0 0 1.07rem 0;
    }

    .EPiServerForms textarea.FormTextbox__Input {
      min-height: 4.2em;
      resize: vertical;
    }

    .EPiServerForms .Form__Element label,
    .EPiServerForms .Form__Element .Form__Element__Caption {
      display: block !important;
      font-size: 1.01rem;
      line-height: 1.7;
      min-width: 15%;
      padding: 0;
      vertical-align: top;
    }

    .EPiServerForms .Form__Element .Form__Element__ValidationError {
      color: #f44336;
      display: block;
      padding-top: 0.35em;
      word-wrap: break-word;
    }

    .EPiServerForms .hide {
      display: none !important;
    }

    .EPiServerForms .FormTextbox__Input,
    .EPiServerForms select {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-transition: border-color 0.1s ease-out;
      border: 1px solid $color-gray-border;
      border-radius: 0.28rem;
      box-sizing: border-box;
      color: #3c3c3c;
      font-family: sans-serif;
      font-size: 1.01rem;
      font-weight: 400;
      padding: 0.5rem 0.64rem;
      transition: border-color 0.1s ease-out;
      width: 100%;
      margin: 0;
    }

    .EPiServerForms .FormCaptcha {
      background-color: $color-white-beige;
      border: 2px solid #cdc9c5;
      border-color: #cdc9c5 #fff #fff #cdc9c5;
      border-radius: 6px;
      margin: 1.43em 0;
      padding: 0.71em;
    }

    .EPiServerForms .FormCaptcha .Form__Element__Caption {
      padding-bottom: 0.71rem;
    }

    .EPiServerForms .FormCaptcha .FormCaptcha__Image {
      margin-right: 1em;
    }

    .EPiServerForms .FormCaptcha .FormTextbox__Input {
      vertical-align: top;
      width: 7.1em;
    }

    .EPiServerForms .FormCaptcha .FormCaptcha__Refresh {
      font-size: 0.71em;
      height: auto;
      margin-left: 0.71em;
      width: auto;
    }

    .EPiServerForms .FormChoice--Image .hidden {
      visibility: hidden;
    }

    .EPiServerForms .FormChoice--Image .FormChoice--Image__Item {
      border: 2px solid transparent;
      display: inline-block !important;
      margin: 0.35rem;
      max-width: 45%;
      padding: 2px;
      text-align: center;
    }

    .EPiServerForms .FormChoice--Image__Item:hover {
      border-color: $color-gray;
    }

    .EPiServerForms .FormChoice--Image .FormChoice__Input ~ img {
      border: 2px solid transparent;
      padding: 2px;
      pointer-events:none;
    }

    .EPiServerForms .FormChoice--Image .FormChoice__Input:checked ~ img {
      border: 2px solid rgba(41, 128, 189, 0.5);
    }

    .EPiServerForms .FormChoice--Image__Item__Caption {
      display: none;
    }

    .EPiServerForms .FormChoice--Image {
      background-color: #fff;
    }

    .EPiServerForms .FormChoice--Image .Form__Element__Caption {
      background-color: $color-gray;
    }

    .EPiServerForms .FormRange .FormFileUpload__Input {
      background-color: #fff;
    }

    .EPiServerForms .FormRange .FormRange__Input {
      display: inline;
      width: 50%;
    }

    .EPiServerForms .FormSubmitButton,
    .EPiServerForms .FormResetButton {
      -webkit-font-smoothing: antialiased;
      -webkit-user-select: none;
      background-color: $color-blue;
      border: 1px solid $color-blue;
      border-radius: 3px;
      box-sizing: border-box;
      color: #fff;
      display: inline-block;
      font-family: sans-serif;
      font-size: 1.01rem;
      margin: 15px 20px 0 0 !important;
      outline: none;
      padding: 0.71rem 1.9rem;
      text-align: center;
      text-decoration: none;
      transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
      white-space: nowrap;
      width: auto !important;
    }

    .EPiServerForms .FormSubmitButton:hover {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      cursor: pointer;
    }

    .EPiServerForms .FormResetButton {
      background-color: #fff;
      border: 1px solid $color-gray-border;
      color: $color-black-light;
    }

    .EPiServerForms .FormResetButton:hover {
      background-color: $color-gray-border;
      border-color: $color-gray-border;
      color: #3c3c3c;
      cursor: pointer;
    }

    .EPiServerForms .FormImageSubmitButton {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0 0 2px 0;
      vertical-align: top;
    }

    .EPiServerForms .FormImageSubmitButton:hover {
      background-color: transparent;
      padding: 2px 0 0 0;
    }

    .EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action {
      background-image: -moz-linear-gradient(top, #fff, #ECE8E5);
      background-image: -ms-linear-gradient(top, #fff, #ECE8E5);
      background-image: -webkit-linear-gradient(top, #fff, #ECE8E5);
      background-image: linear-gradient(top, #fff, #ECE8E5);
      border: 1px solid rgba(41, 128, 189, 0.5);
      border-radius: 4px;
      color: initial;
      display: inline-block;
      font-size: 11px;
      text-align: center;
      vertical-align: middle;
    }

    .EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action:disabled {
      color: GrayText;
    }

    .EPiServerForms .Form__NavigationBar .Form__NavigationBar__ProgressBar {
      border: 1px solid rgba(41, 128, 189, 0.5);
      display: inline-block;
      vertical-align: middle;
      width: 40%;
    }

    .EPiServerForms
      .Form__NavigationBar
      .Form__NavigationBar__ProgressBar
      .Form__NavigationBar__ProgressBar--Progress {
      background-color: rgba(41, 128, 189, 0.5);
      height: 0.71rem;
      width: 0%;
    }

    .EPiServerForms
      .Form__NavigationBar
      .Form__NavigationBar__ProgressBar
      .Form__NavigationBar__ProgressBar--Text {
      display: none;
    }

    .EPiServerForms .FormStep .FormStep__Description {
      margin-bottom: 0.71rem;
    }

    .EPiServerForms .EditView__InvisibleElement.FormStep {
      background-color: rgba(184, 192, 197, 0.6);
      border-color: rgba(184, 192, 197, 0.6);
      border-radius: 4px;
      box-shadow: 3px 3px 5px #ccc;
      color: #000;
      display: block;
      font-family: Verdana;
      padding: 0.14rem;
    }

    .EPiServerForms .EditView__InvisibleElement.FormStep .FormStep__Warning {
      background-color: #fff8aa;
      color: red;
      display: block;
      word-wrap: break-word;
    }

    .EPiServerForms .EditView__InvisibleElement.FormHidden {
      border: 1.5px outset rgba(184, 192, 197, 0.6);
      border-radius: 4px;
      display: inline-block;
      min-width: 12.8rem;
      padding: 0.14rem 0.71rem;
    }
  }
}
