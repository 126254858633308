.c-panelblock-header {
  margin-top: 0;
  margin-bottom: 0;
  color: $color-blue;

  &.is-documents {
    color: $color-110-warm-grey;
  }

  &.yellowbox {
    border-top-right-radius: 22px;
    background-color: $color-yellow;
    margin-bottom: 8px;
    padding: 4px 16px;
  }

  &.blueline {
    border-top: 2px solid;
    border-color: $color-blue;
    margin-bottom: 8px;
    margin-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0;
  }

  &.none {
    text-align: left;
    font-size: 20px;
    line-height: 1.25;
  }
}

div[class^="l-section"] .wysiwyg,
div[class^="l-section"] .c-tag-group-panel .c-tag-group-panel-title,
div[class^="l-section"] .c-panelblock-header.none {
  max-width: 100%;
}