.c-latest-news-block {
  padding: 16px;

  img {
    border-radius: 3px;
  }
}

.c-latest-news-block-wrapper {
  padding: 16px;
  margin: -16px -16px 16px -32px;

  &:hover {
    background-color: $color-block-hover-gray;
  }
}

.c-latest-news-block-article-container {
  padding-right: 16px;

  &:hover {
    text-decoration: none;
  }
}

.c-latest-news-block-article-lead {
  color: $color-110-warm-grey;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.75;
  margin: 0;
  word-break: break-word;

  @media (min-width: $bkpt-tablet) {
    &.sub-news-intro {
      padding-top: 5px;
    }
  }

  @media (max-width: $bkpt-tablet) {
    &.sub-news-intro {
      display: none;
    }
  }
}

.c-latest-news-block-article-title {
  color: $color-110-warm-grey;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 40px;
  margin: 16px 0 8px 0;
}

.c-latest-news-block-article-title-small {
  color: $color-110-warm-grey;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 16px;
}

@media (min-width: $bkpt-tablet) {
  .c-latest-news-block-article-title-small {
    line-height: 28px;
  }
}

.c-latest-news-block-body {
  margin: 0;
}

@media (min-width: $bkpt-tablet) {
  .c-latest-news-block-body {
    background-color: #f5f3f2;
  }
}

.c-latest-news-block-col-50 {
  min-height: 330px;
  padding: 16px;
  margin: -16px 16px 16px -16px;
  width: 50%;

  @media (max-width: $bkpt-tablet) {
    &:hover {
      background-color: $color-block-hover-gray;
    }
  }
}

@media (min-width: $bkpt-tablet) {
  .c-latest-news-block-col-50 {
    min-height: fit-content;
    margin: 0;
    padding: 0;
    padding-bottom: 16px;
  }
}

@media (max-width: $bkpt-tablet) {
  .c-latest-news-block-col-50 {
    margin: -16px 0 16px 0;
  }
}

@media (max-width: $bkpt-mobile-landscape) {
  .c-latest-news-block-col-50 {
    padding: 0;
  }
}

.c-latest-news-block-date {
  color: $color-110-warm-grey;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
}

.c-latest-news-block-header {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 16px;
  max-width: 100%;
  padding: 0;
}

.c-latest-news-block-image {
  margin-bottom: 16px;
  mix-blend-mode: multiply;
}

.c-latest-news-block-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.c-latest-news-block-title {
  color: $color-110-warm-grey;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  margin: 0;
  padding: 0;
}

a.c-latest-news-block-view-all {
  color: $color-blue;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding-right: 16px;
  text-align: right;
  transition: all 0.225s ease-in-out;

  &:hover {
    text-decoration: none;
    color: $color-blue-dark;
  }
}

@include mediaSmallerThan(tablet) {
  .c-latest-news-block {
    .l-section-block {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .c-latest-news-block {
    padding: 0px;
  }

  .c-latest-news-block-wrapper {
    margin-right: 0px;
  }
}

@include mediaSmallerThan(mobile-l) {
  .c-latest-news-block-col-50 {
    width: 100%;
  }
}

@media (min-width: $bkpt-tablet) {
  .c-updated-news-block-body-container {
    display: flex;

    &.main-news-container {
      padding-top: 16px;
    }

    &.sub-news-container {
      height: 180px;
    }
  }

  .c-updated-news-block-body-image {
    display: flex;
    flex-basis: 50%;
    width: 50%;
  }

  .c-updated-news-block-body-content {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    width: 50%;
    align-items: flex-start;
    justify-content: flex-start;

    &.main-news {
      padding: 0 0 0 16px;
    }

    &.sub-news {
      padding: 5px 0 0 10px;
      height: -webkit-fill-available;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 6;
    }
  }
}

@media (min-width: $bkpt-tablet) {
  .c-updated-news-block-main-news-title {
    color: #11387f;
  }
}

@media (min-width: $bkpt-mobile-landscape) {
  .c-updated-news-block-right-news {
    padding-right: 0;
  }

  .c-updated-news-block-left-news {
    padding-left: 0;
  }
}

@media (min-width: $bkpt-tablet) {
  .c-updated-news-block-images {
    height: fit-content;
  }

  .c-updated-news-block-right-news {
    padding-left: 16px;
  }

  .c-updated-news-block-left-news {
    padding-right: 16px;
  }

  .c-updated-news-hyperlink {
    padding: 0;
    line-height: 0;
    display: inline-block;
  }
}