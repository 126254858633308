#service-offering {
  padding: 0 32px;

  .l-section.l-section-100.l-section-padding {
    padding: 0;
  }

  p,
  h3 {
    color: $color-black-offering;
  }

  img {
    background: no-repeat 50%;
    background-size: cover;
  }

  //break content when content overflows in button
  .service-button {
    white-space: normal;
    text-align: left;
  }
}

// Styles for Service Hero Block
.service-hero-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 502px;
  position: relative;
}

.service-hero-block-image {
  position: absolute;
  height: 100%;
  object-fit: cover;
  width: calc(100vw - var(--scrollbar-width));
  margin: 0 -32px;
}

.service-hero-block-content-wrapper {
  width: 100%;
  padding: 100px 0;
}

.service-hero-block-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 700px;
  padding: 32px;
  background-color: $color-white;
  position: relative;
  word-break: break-word;
}

.service-hero-block-title {
  letter-spacing: -1.26px;
  color: $color-blue;
  margin: 0 0 32px 0;
  padding: 0;
}

.service-hero-block-buttons {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 28px;
}

.service-hero-block-overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  position: absolute;
  height: 100%;
  width: calc(100vw - var(--scrollbar-width));
  margin: 0 -32px;
}

// Styles for CTA Image Block and Image Text Block
.image-text-block-container,
.image-cta-block-container {
  display: flex;
  padding: 80px 0;

  &.left-aligned-img {
    flex-direction: row-reverse;
  }

  &.with-background {
    background: $color-gray;
    box-shadow: -20vw 0 0 0 $color-gray, -40vw 0 0 0 $color-gray, 20vw 0 0 0 $color-gray,
      40vw 0 0 0 $color-gray;
  }
}

.image-text-block-container {
  gap: 48px;
}

.image-cta-block-container-wrapper {
  display: flex;
  background: $color-gray;
  padding: 24px;
  gap: 32px;

  &.left-aligned-img {
    flex-direction: row-reverse;
  }
}

.image-text-block-body-wrapper,
.image-cta-block-body-wrapper {
  display: flex;
  flex: 50%;
}

.image-text-block-body,
.image-cta-block-body {
  word-break: break-word;
  width: 100%;

  .c-button {
    margin-top: 48px;
  }

  h2 {
    padding: 0;
    margin: 0;
  }
}

.image-text-block-body {
  align-self: flex-start;
}

.image-cta-block-body {
  align-self: center;
}

.image-text-block-title-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.image-text-block-title-icon {
  display: flex;
  max-width: 37px;
  height: 37px;
}

.image-text-block-title,
.image-cta-block-title {
  letter-spacing: -0.96px;
  color: $color-blue;
}

.image-text-block-description {
  margin-top: 24px;

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.75;
  }
}

.image-cta-block-description {
  margin-top: 24px;
  letter-spacing: -0.16px;

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.75;
  }
}

.image-text-block-img-container,
.image-cta-block-img-container {
  flex: 50%;
  align-self: flex-start;
  line-height: 0;
}

.image-text-block-img,
.image-cta-block-img {
  aspect-ratio: 3 / 2;
}

.image-text-block-list-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
}

.image-text-block-arrow-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.image-text-block-arrow {
  height: 24px;
  align-self: flex-start;
}

.image-text-block-list-label {
  color: $color-black-offering;
}

.image-text-block-acc-container {
  margin-top: 24px;

  .image-text-block-accordion {
    .image-text-block-accordion-header {
      padding: 20px 0px;
      color: $color-black-offering;
      border-top: 1px solid $color-service-acc-border;
      letter-spacing: -0.16px;
      cursor: pointer;
    }

    &:last-of-type {
      border-bottom: 1px solid $color-service-acc-border;
    }

    .image-text-block-acc-title {
      display: flex;
      gap: 8px;
    }

    .image-text-block-accordion-tabs-head-icon {
      color: $color-110-warm-grey;
      padding-top: 3px;
      margin-left: auto;
    }

    .image-text-block-accordion-body {
      display: none;
    }

    &.open .image-text-block-accordion-body {
      padding: 0px 0px 16px 0px;
      display: block;
      color: $color-black-offering;
    }

    &.open .image-text-block-accordion-header .image-text-block-accordion-tabs-head-icon {
      transform: rotate(180deg);
      width: 24px;
      height: 24px;
    }

    &.open .image-text-block-acc-service-header .icon-minus {
      display: block;
    }

    &.open .image-text-block-acc-service-header .icon-plus {
      display: none;
    }
  }
}

.image-text-block-acc-service-header {
  padding: 16px 0px;
  letter-spacing: -0.36px;
  border-top: 1px solid $color-service-acc-border;
  cursor: pointer;

  .icon-minus {
    display: none;
  }

  .image-text-block-accordion-tabs-head-icon {
    -webkit-text-stroke: 2px $color-white;
  }
}

.image-text-block-service-acc-container {
  display: flex;
  gap: 16px;
  flex-direction: column;

  .image-text-block-services-list {
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
    letter-spacing: -0.16px;
    color: $color-black-offering;

    .icon-chevron-right {
      color: $color-blue;
    }
  }
}

.number-facts-heading {
  letter-spacing: -0.72px;
  margin: 24px 0;
}

.number-facts-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 32px 30px;
  flex-wrap: wrap;
}

.number-facts {
  background: $color-white;
  padding: 16px;
  display: flex;
  min-width: 139px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.facts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.number-facts-icon {
  width: 32px;
  height: 32px;
  align-self: center;
}

.facts-number {
  letter-spacing: -0.72px;
  color: $color-blue;
}

.facts-title {
  letter-spacing: -0.16px;
  width: 121px;
  text-align: center;
}

// Styles for Service List and Case Stories Block
.services-list {
  display: flex;
  flex-direction: column;
  padding: 80px 0;

  &.with-background {
    background: $color-gray;
    box-shadow: -20vw 0 0 0 $color-gray, -40vw 0 0 0 $color-gray, 20vw 0 0 0 $color-gray,
      40vw 0 0 0 $color-gray;
  }
}

.services-list-title {
  margin: 0 0 48px 0;
  padding: 0;
  letter-spacing: -0.96px;
  color: $color-blue;
}

.services-list-container-wrapper {
  display: grid;
  column-gap: 24px;
  row-gap: 60px;
  grid-template-columns: repeat(3, 1fr);
}

.service-item:hover {
  text-decoration: none;
}

.service-item,
.case-story-item {
  .service-item-image,
  .case-story-item-image {
    width: 100%;
    position: relative;
    line-height: 0;

    .overlay-image {
      position: absolute;
      right: 0;
      background-color: $color-blue-light;
      overflow: hidden;
      width: 100%;
      height: 0;
      top: 0;
      transition: 0.5s ease;
      opacity: 0.5;
    }
  }

  &:hover .overlay-image {
    bottom: 0;
    height: 100%;
  }

  &.is-anchor {
    &:hover {
      .service-item-title,
      .case-story-item-title {
        color: $color-blue !important;
      }
    }
  }
}

.case-story-item-image {
  .overlay-image {
    z-index: 10;
  }
}

.service-item-image-img,
.case-story-item-image-img {
  aspect-ratio: 3 / 2;
}

// .service-item-label {
//   position: absolute;
//   bottom: 12px;
//   background-color: $color-white;
//   padding: 8px;
//   letter-spacing: -0.14px;
//   color: $color-light-gray;
//   display: flex;
//   gap: 6px;
//   justify-content: space-between;
//   align-items: center;
// }

.service-item-content,
.case-story-item-content {
  word-break: break-word;
  margin-top: 24px;
}

.service-item-title {
  margin: 0 0 16px;
  letter-spacing: -0.72px;
  text-align: left;
}

.case-stories-container {
  display: flex;
  justify-content: center;
}

.case-stories-wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  padding: 80px 0;
}

.case-stories-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.case-stories-title {
  margin: 0;
  padding: 0;
  letter-spacing: -0.96px;
  color: $color-blue;
}

.case-stories-list-container,
.case-stories-side-title-wrapper {
  a {
    text-decoration: none;
  }
}

.case-stories-side-title {
  color: $color-blue;
  letter-spacing: -0.16px;
  margin-right: 16px;
}

.icon-arrow-right {
  display: inline-block;
  width: 24px;
  height: 24px;
  color: $color-blue;
}

.case-stories-list-container-wrapper {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  column-gap: 24px;
  margin-right: -32px;
  scrollbar-width: none;
}

.case-stories-list-container:last-of-type {
  margin-right: 32px;
}

.case-story-item {
  display: block;
  width: 304px;
  scroll-snap-align: start;
}

.case-story-item-title {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
}

@media screen and (min-width: $bkpt-desktop) {
  .image-text-block-container,
  .image-cta-block-container,
  .services-list,
  .case-stories-wrapper {
    padding: 100px 0;
  }

  .image-text-block-container {
    gap: 80px;
  }

  .image-text-block-body {
    padding-top: 54px;
  }

  .image-cta-block-container-wrapper {
    gap: 48px;
    padding: 32px;
  }

  .services-list-container-wrapper {
    row-gap: 80px;
    column-gap: 31px;
  }

  .case-stories-list-container-wrapper {
    column-gap: 32px;
  }

  .case-story-item {
    width: 394px;
  }
}

@media screen and (max-width: $bkpt-tablet-portrait) {
  .service-hero-block {
    min-height: 440px;
  }

  .service-hero-block-content-wrapper {
    padding: 80px 0;
  }

  .service-hero-block-content {
    max-width: 600px;
  }

  .service-hero-block-title {
    font-size: 40px;
    letter-spacing: -1.2px;
    margin-bottom: 24px;
  }

  .service-hero-block-description {
    font-size: 16px;
    line-height: 1.75;
  }

  .service-hero-block-buttons {
    margin-top: 32px;
  }

  .image-text-block-container {
    flex-wrap: wrap;
    gap: 42px;
  }

  .image-cta-block-container-wrapper,
  .image-cta-block-container-wrapper.left-aligned-img {
    flex-direction: column;
  }

  .image-text-block-body-wrapper,
  .image-cta-block-body-wrapper {
    order: 2;
  }

  .image-cta-block-img-container {
    align-self: stretch;
  }

  .services-list-container-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .case-story-item {
    width: 340px;
  }

  .service-hero-block-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
  }
}

@media screen and (max-width: $bkpt-mobile-landscape) {
  #service-offering {
    padding: 0 16px;
  }

  .service-hero-block-content-wrapper,
  .image-cta-block-container,
  .image-text-block-container,
  .services-list,
  .case-stories-wrapper {
    padding: 60px 0;
  }

  .service-hero-block {
    min-height: unset;
  }

  .service-hero-block-image {
    margin: 0 -16px;
  }

  .service-hero-block-content {
    width: 100%;
    padding: 16px;
  }

  .service-hero-block-title {
    font-size: 32px;
    letter-spacing: -0.96px;
  }

  .image-text-block-container {
    gap: 24px;
  }

  .image-cta-block-container-wrapper {
    padding: 16px;
  }

  .image-text-block-body,
  .image-cta-block-body {
    .c-button {
      margin-top: 32px;
    }
  }

  .image-text-block-title-icon {
    max-width: 28px;
    height: 28px;
  }

  .image-text-block-title,
  .image-cta-block-title {
    font-size: 24px;
    letter-spacing: -0.72px;
  }

  .image-text-block-description {
    margin-top: 16px;
  }

  .image-text-block-list-container,
  .image-text-block-acc-container {
    margin-top: 16px;
  }

  .number-facts-heading {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 16px 0 24px 0;
  }

  .number-facts-wrapper {
    flex-direction: column;
    gap: 16px;
  }

  .number-facts {
    width: 100%;
  }

  .facts-title {
    text-align: left;
  }

  .services-list-title,
  .case-stories-title {
    font-size: 24px;
    letter-spacing: -0.72px;
  }

  .services-list-container-wrapper {
    row-gap: 48px;
    grid-template-columns: 1fr;
  }

  .service-item-title {
    font-size: 20px;
    letter-spacing: -0.6px;
  }

  .case-stories-list-container-wrapper {
    margin-right: -16px;
  }

  .case-stories-list-container:last-of-type {
    margin-right: 16px;
  }

  .case-story-item-title {
    font-size: 20px;
    line-height: 1.17;
    letter-spacing: -0.6px;
  }

  .service-hero-block-overlay {
    margin: 0 -16px;
  }
}
