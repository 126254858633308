.wysiwyg {
  margin: 0 auto;
  padding: 2rem 0 0 0;

  &.no-padding {
    padding: 0;
  }

  .intro-text {
    font-size: 20px;
    line-height: 1.7;
    font-weight: 300;
    margin-top: 0;

    &:first-child {
      margin-top: 1rem;
    }
  }

  hr {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  p {
    padding: 8px 0 0 0;
    color: $color-110-warm-grey;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.71;

    a,
    a:visited {
      color: $color-font-blue;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    strong {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.7;
      color: $color-110-warm-grey;
    }
  }

  a {
    color: $color-font-blue;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.71;
  }

  a:visited {
    color: $color-font-blue;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  iframe {
    max-width: 100%;
    max-height: 335px;
    border: 1px solid $color-gray;
    border-radius: 3px;
  }

  ul {
    margin: 0 0 8px 0;
    padding-left: 0;
    padding-right: 16px;

    li {
      color: $color-110-warm-grey;
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin: 8px 0;
      padding-left: 24px;
      position: relative;
      text-align: left;

      &:before {
        content: "\00b7";
        color: $color-grey;
        font-size: 65px;
        left: 0;
        line-height: 28px;
        position: absolute;
      }
    }

    &.link-list {
      li {
        padding-left: 1.5rem;

        &:before {
          color: #3c3c3c;
          content: "\f105";
          font-family: "ALicons";
          font-size: 1.6rem;
          top: -0.15em;
        }

        a {
          color: #4456af;

          &:hover {
            color: #3c3c3c;
          }
        }
      }
    }
  }

  h1 {
    font-weight: 100;
    padding: 0;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.4;
    color: $color-blue;
  }

  .al-earth {
    background-color: $color-earth;
    padding: 0.75rem 0 0.75rem 1rem;
  }

  .al-sun {
    background-color: $color-sun;
    padding: 0.75rem 0 0.75rem 1rem;
  }

  .al-water {
    background-color: $color-water;
    padding: 0.75rem 0 0.75rem 1rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.3;
    padding: 1.5rem 0 0 0;
    font-weight: 300;
    margin: 0;
    color: $color-blue;
  }

  h4 {
    &:first-child {
      padding-top: 0;
    }
  }

  &.is-sidebar {
    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .align-left {
    @extend .align-left;
  }

  .align-right {
    @extend .align-right;
  }

  blockquote {
    font-size: 1.2rem;
    position: relative;
    padding-left: 2rem;
    margin: 2rem 1rem 2rem 0;
    box-sizing: border-box;
    line-height: 1.6;

    &:after {
      font-weight: 300;
      content: "”";
      font-size: 5.2rem;
      line-height: 0;
      color: #fc3;
      position: absolute;
      top: 2rem;
      left: 0;
    }
  }

  blockquote {
    font-size: 1.2rem;
    position: relative;
    padding-left: 2rem;
    margin: 2rem 1rem 2rem 0;
    box-sizing: border-box;
    line-height: 1.6;

    &:after {
      font-weight: 300;
      content: "”";
      font-size: 5.2rem;
      line-height: 0;
      color: #fc3;
      position: absolute;
      top: 2rem;
      left: 0;
    }
  }

  .doc-link {
    color: $color-blue;

    &:before {
      content: "\e921";
      font-family: "ALicons";
      color: #cdc9c5;
      display: inline-block;
      margin-right: 6px;
      font-size: 14px;
    }
  }

  .wysiwyg>*:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  .wysiwyg>*:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.news-article {
  padding: 0px;
  word-break: break-word;

  pre {
    font-family: inherit;
    white-space: pre-wrap;
  }

  p {
    padding: 0;
    line-height: normal;
    margin: revert;

    a {
      color: blue;
    }

    a:visited {
      color: rgb(85, 26, 139);
    }

    strong {
      font-weight: 700;
      line-height: normal;
    }
  }

  ul {
    list-style: disc;
    margin: 0;
    padding-left: revert;

    li {
      display: list-item;
      line-height: normal;
      margin: revert;
      padding-left: revert;

      &:before {
        display: none;
      }
    }
  }

  h1 {
    font-weight: 300;
  }

  h2 {
    padding: 0;
    line-height: normal;
    font-weight: 300;
    margin: revert;
    font-size: 32px;
    color: $color-blue;
  }

  h3,
  h4 {
    padding: 0;
    font-weight: 300;
    line-height: normal;
    margin: revert;
  }

  h3 {
    color: $color-blue;
  }

  table {
    line-height: normal;
    border-top: 0px;
    border-bottom: 0px;

    td {
      padding: 0;
    }
  }
}

.align-left {
  float: left;
  padding-right: 2.1rem;
  padding-bottom: 0.7rem;
  padding-top: 0.34rem;
}

.align-right {
  float: right;
  padding-left: 2.1rem;
  padding-bottom: 0.7rem;
  padding-top: 0.34rem;
}

img {
  width: 100%;
  height: auto;

  &.align-right,
  &.align-left {
    width: auto;
    max-width: 100%;
  }

  &.align-center {
    margin: auto;
    display: block;
    padding-bottom: 0.7rem;
    padding-top: 0.7rem;
  }
}

//links in investors->publication Q1 links row
#links-archive {
  h3 {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-size: 1rem !important;
  }
}

.wysiwyg {
  &> :first-child {
    margin-top: 0;
    padding-top: 0;
  }
}

@include mediaSmallerThan(tablet) {
  .wysiwyg {
    h1 {
      font-size: 2.2rem;
      padding: 1em 0 0.8em;
    }

    h2 {
      font-size: 1.6rem;
      padding: 1em 0 0.5em;
      font-weight: 100;
      margin: 0;
      color: $color-blue;
    }

    h3 {
      font-size: 1.4rem;
      padding: 1em 0 0.5em 0;
      margin: 0;
      color: $color-blue;
    }

    h4 {
      font-size: 1.1rem;
      font-weight: 700;
      padding: 0.9rem 0 0.7em;
      margin: 0;
    }

    iframe {
      width: 100%;
      height: 315px;
    }

    img {

      &.align-right,
      &.align-left {
        display: block;
        float: none;
        padding: 0;
        margin: 1rem auto 2rem auto;
      }
    }

    .video-unit {
      width: 100%;
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
    }

    p,
    h4 {
      &.intro-text {
        font-size: 1.4rem;
        line-height: 1.4em;
        padding: 0.5em 0 1em;
      }
    }
  }
}

.contact-item {
  color: #3c3c3c;
  font-size: 1.4rem;
  line-height: 1.65;

  &.sidebar {
    ul {
      margin-left: 0px;
    }

    hr {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  hr {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  &>*:first-child {
    margin-top: 0;
    padding-top: 0;

    li {
      margin-top: 0;
    }
  }

  &.info-box-content {
    ul {
      margin: 1rem 0 1rem 0.5rem;
    }
  }

  strong {
    font-weight: 700;
  }

  .clearfix {
    &:after {
      clear: both;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 1.7;
    padding: 0.75rem 0;
  }

  h1 {
    font-size: 4.8rem;
    font-weight: 100;
    line-height: 1.2;
    padding: 0.4em 0;
  }

  h2 {
    font-size: 3.6rem;
    font-weight: 300;
    line-height: 1.3;
    padding: 0.8em 0 0.4em 0;
    color: $color-blue;
  }

  h3 {
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 1.3;
    padding: 1em 0 0.4em 0;
    color: $color-blue;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.3;
    padding: 1em 0 0.25em 0;
  }

  em {
    font-style: italic;
  }

  a {
    color: #4456af;

    &:hover {
      color: #3c3c3c;
      text-decoration: underline;
    }

    &.list-link {
      display: inline-block;
      padding-left: 1.2rem;
      position: relative;

      &:before {
        color: #3c3c3c;
        content: "\f105";
        display: block;
        font-family: "ALicons";
        font-size: 1.6rem;
        left: 0;
        position: absolute;
        top: -0.1em;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &.doc-link {
      display: inline-block;
      padding-left: 1.3rem;
      position: relative;

      &:before {
        content: "\e921";
        font-family: "ALicons";
        display: block;
        font-size: 14px;
        height: 1em;
        left: 0;
        position: absolute;
        width: 1rem;
      }
    }
  }

  .intro-text {
    font-size: 1.8rem;
    line-height: 1.7;
  }

  blockquote {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1.6rem;
    margin: 4rem 2rem 4rem 0rem;
    padding-left: 4rem;
    position: relative;
    vertical-align: top;

    &:after {
      color: #fc3;
      content: "”";
      font-family: "Roboto", sans-serif;
      font-size: 7.2rem;
      font-weight: 300;
      left: 0;
      line-height: 0;
      position: absolute;
      top: 3rem;
    }
  }

  &.wysiwyg-footer {
    a {
      color: $color-black-light;
      text-decoration: none;

      &:hover {
        color: #3c3c3c;
        text-decoration: underline;
      }
    }

    p {
      padding: 0.35em 0;
    }
  }

  ol {
    counter-reset: item;
    margin: 2rem 0 2rem 1rem;

    li {
      color: #3c3c3c;
      display: block;
      font-size: 1.4rem;
      line-height: 1.65;
      list-style-type: none;
      margin: 0.75em 0;
      padding-left: 2rem;
      position: relative;

      &:before {
        color: $color-black-light;
        content: counter(item) ". ";
        counter-increment: item;
        font-family: arial, sans-serif;
        font-family: roboto;
        font-size: 1em;
        left: 0;
        position: absolute;
        top: 0em;
      }
    }
  }

  ul {
    margin: 2rem 0 2rem 1rem;

    li {
      color: #3c3c3c;
      display: block;
      font-family: roboto;
      line-height: 28px;
      list-style-type: none;
      margin: 0.75em 0;
      padding-left: 2rem;
      position: relative;

      &:before {
        color: $color-black-light;
        content: "\00b7";
        font-family: roboto, arial, sans-serif;
        font-size: 1.7em;
        left: 0;
        position: absolute;
      }
    }

    &.link-list {
      li {
        padding-left: 1.5rem;

        &:before {
          color: #3c3c3c;
          content: "\f105";
          font-family: "ALicons";
          font-size: 1.6rem;
          top: -0.15em;
        }

        a {
          color: #4456af;

          &:hover {
            color: #3c3c3c;
          }
        }
      }
    }
  }

  &.sidebar-list-circle {
    ul {
      margin: 3rem 0 3rem 0rem;
    }

    &.inverted {
      li {
        color: #fff;

        &:before {
          color: #a79c95;
        }
      }
    }

    &.callout {
      li {
        &:before {
          color: #fc3;
        }
      }
    }
  }

  &.plus {
    p {
      font-size: 1.6rem;

      &.intro-text {
        font-size: 2.4rem;
        text-indent: 0;
      }
    }

    h1 {
      font-size: 6.2rem;
    }

    h2 {
      font-size: 4.6rem;
    }

    h3 {
      font-size: 3.2rem;
    }

    h4 {
      font-size: 2.2rem;
    }
  }

  .align-right {
    float: right;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-top: 0.5em;
  }

  .align-left {
    float: left;
    padding-bottom: 1rem;
    padding-right: 3rem;
    padding-top: 0.5em;
  }

  .align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  table {
    border-bottom: 1px solid $color-gray-border;
    border-collapse: collapse;
    border-spacing: 0;
    border-top: 1px solid $color-gray-border;
    color: #3c3c3c;
    display: table;
    text-align: left;

    th {
      padding: 1rem 1.5rem;
    }

    td {
      padding: 0.6rem 1.5rem;
    }
  }

  .zebra {
    tr {
      &:nth-child(even) {
        background-color: $color-gray;
      }

      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }

  label {
    display: inline-block;
    margin-right: 10px;
  }

  .orientationVertical {
    label {
      display: block;
      padding-bottom: 3px;
      padding-top: 3px;
    }
  }

  form {
    font-size: 1.4rem;
    line-height: 1.6;

    table {
      border: 0;

      td {
        padding: 1rem 0;
      }
    }
  }
}

.contact-item.info-box-content h1,
.contact-item.info-box-content h2,
.contact-item.info-box-content h3,
.contact-item.info-box-content h4,
.contact-item.info-box-content p,
.contact-item.info-box-content a,
.contact-item.info-box-content span,
.contact-item.info-box-content li {
  color: #fff;
}

.contact-item .clearfix:before,
.contact-item .clearfix:after {
  content: "";
  display: table;
}

.contact-item h1,
.contact-item h2,
.contact-item h3,
.contact-item h4,
.contact-item p {
  color: #3c3c3c;
}

.contact-item h5,
.contact-item h6 {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.3;
  padding: 1em 0 0.25em 0;
}

.contact-item.plus h5,
.contact-item.plus h6 {
  font-size: 1.8rem;
}

.contact-item th,
.contact-item thead {
  background: #cdc9c5;
  font-family: sans-serif;
  font-weight: bold;
}

.contact-item input[type="radio"],
.contact-item input[type="checkbox"] {
  margin-right: 1rem;
}

.contact-item form table .input-select-wrapper ul,
.contact-item form table .input-select-wrapper ol {
  margin: 0;
}

.contact-item form table .input-select-wrapper ol:before,
.contact-item form table .input-select-wrapper ul:before,
.contact-item form table .input-select-wrapper li:before {
  content: none;
}