$block-name: c-document-list-item;

.#{$block-name} {
  display: flex;
  flex-direction: row;
  min-height: 70px;
  position: relative;
  padding: 16px 16px 16px 56px;

  @media screen and (max-width: 580px) {
    padding-left: 16px;
  }

  &.no-margin {
    margin: 0;
  }

  &:hover {
    background-color: $color-5-warm-grey;
    text-decoration: none;

    .#{$block-name}-icon-container {
      visibility: visible;
    }
  }

  .#{$block-name}-icon-container {
    visibility: hidden;
    background-color: $color-blue;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;

    i {
      font-size: 16px;
      color: white;
    }
  }

  .#{$block-name}-thumbnail {
    max-width: 50px;
    min-width: 50px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    .#{$block-name}-thumbnail-icon {
      font-size: 42px;
      color: red;
    }
  }

  .#{$block-name}-tail {
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 0;

    overflow: hidden;

    .#{$block-name}-tail-container {
      padding-right: 30px;
      width: 100%;
    }

    .#{$block-name}-main-text {
      display: block;
      color: $color-black;
      line-height: 1.3;
      font-size: 16px;
    }

    .#{$block-name}-word-break {
      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;
      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .#{$block-name}-secondary-text {
      display: block;
      font-size: small;
      color: $color-grey;
    }
  }

  &.has-line-under {
    border-bottom: 1px solid #c3bcb8;
  }
}