$block-name: c-quote;

.#{$block-name} {
  z-index: 999;
  position: fixed;
  bottom: 0;
  margin-left: 0;
  left: 0;
  width: 100%;
  background-color: $color-blue;

  &.is-floating {
    z-index: auto;
    position: static;
    bottom: 0;
    top: auto;
  }

  .c-quote-container {
    width: 100%;
    padding: 1.5rem 0;
    text-align: center;

    .wrap {
      display: inline-block;
      font-size: 1.6rem;
      line-height: 1.3;
      font-weight: 300;
      color: #fff;
      cursor: pointer;
    }
    .c-request-quote-indicator {
      display: inline-block;
      position: relative;
      top: 0;
      width: 40px;
      height: 40px;
      margin-left: 20px;
      background-color: #fff;
      border-radius: 5px;

      &.is-active {
        .c-request-quote-icon-container {
          transform: rotate(180deg);
          top: 0;
        }
      }
      .c-request-quote-icon-container {
        transition: all 0.2s ease;
        position: relative;
        top: 5px;

        .c-request-quote-icon {
          color: $color-blue;
        }
      }
    }
  }

  //quote content
  .#{$block-name}-body {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 0;
    margin-top: 0;

    background-color: $color-gray;
    transition: all 0.5s ease;

    &.is-visible {
      overflow: visible;
      max-height: 800px;
      height: 600px;
    }

    &.messageHeight {
      height: 800px;
    }

    .c-quote-body-container {
      background-color: $color-gray;
      width: 100%;
      padding: 40px 20px 40px;

      .c-quote-body-title {
        h3 {
          font-size: 28px;
          font-weight: 100;
          margin-bottom: 40px;
          margin-top: 10px;
          position: relative;
        }
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      margin-left: -18px;
      top: 0;
      border: 18px solid transparent;
      border-top-color: $color-blue;
    }
  }
  .c-contact-us-form {
    background-color: $color-gray;
  }
}

@include mediaSmallerThan(tablet) {
  .#{$block-name}::-webkit-scrollbar {
    display: none;
  }
  .#{$block-name} {
    background-color: $color-gray;
    overflow-y: scroll;

    &.is-floating {
      margin-bottom: 0;
      margin-top: 32px;
    }
    &.is-active {
      top: 0;
      &.is-floating {
        margin-top: 0;
        z-index: 9999;
        position: fixed;
      }
    }

    .#{$block-name}-body {
      .c-quote-body-close {
        display: none;
      }

      &:after {
        display: none;
      }

      .c-quote-body-container {
        padding-top: 10px;
      }
    }

    .c-quote-container {
      .wrap {
        color: black;
      }

      .c-request-quote-indicator {
        background-color: $color-blue;

        .c-request-quote-icon-container {
          .c-request-quote-icon {
            color: white;
          }
        }
      }
    }
  }
}
