.c-share-link {
  @extend .l-flex-item;
  width: 30px;
  height: 28px;

  a {
    transition: all 100ms ease-out;
    color: $color-white;
    fill: $color-white;
    display: flex;
    height: inherit;
    justify-content: center;
    text-decoration: none;
  }

  i {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
}
