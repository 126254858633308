.c-button {
  @include c-button-base();
  white-space: nowrap;
 
  &:hover {
    text-decoration: none;
  }
 
  &.is-full-width {
    width: 100%;
  }
 
  &.is-small {
    font-size: 1rem;
    padding: 0.7rem 0.9rem;
  }
 
  &.is-medium {
    font-size: 16px;
    padding: 16px 24px;
  }
 
  &.is-large {
    font-size: 1.1rem;
    padding: 0.75rem 2rem;
  }
 
  &.is-primary {
    background-color: $color-footer-link-blue;
    border: 1px solid $color-footer-link-blue;
    color: #fff;
 
    &:hover {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      color: #fff;
    }
  }
 
  &.is-grey {
    background-color: $color-30-warm-grey;
    border: 1px solid $color-30-warm-grey;
    color: #000;
 
    &:hover {
      background-color: $color-70-warm-grey;
      border-color: $color-70-warm-grey;
      color: #000;
    }
  }
 
  &.is-cta {
    background-color: $color-innovation;
    border: 1px solid $color-innovation;
    color: #fff;
 
    &:hover {
      background-color: $color-innovation-dark;
      border-color: $color-innovation-dark;
      color: #fff;
    }
  }
 
  &.is-border {
    border: 1px solid $color-white;
  }
 
  &.is-default {
    background-color: #fff;
    border: 1px solid $color-gray-border;
    color: $color-black-light;
 
    &:hover {
      background-color: $color-gray-border;
      border-color: $color-gray-border;
      color: #3c3c3c;
    }
  }
 
  &.is-disabled {
    background-color: $color-blue-disabled;
    border-color: $color-blue-disabled;
    color: #fff;
    cursor: auto;
    pointer-events: none;
  }
 
  &.c-req-button-disabled {
    background-color: $color-40-warm-grey;
    border: $color-gray-border;
    color: #fff;
    cursor: auto;
    pointer-events: none;
  }
 
  &.is-primary-default {
    background-color: $color-white;
    border: 1px solid $color-blue;
    color: $color-blue;
 
    &:hover {
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-white;
    }
  }
}
 
/* these styles for tinymce*/
.button-small-primary {
  @include c-button-base();
 
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
 
  background-color: $color-footer-link-blue;
  border: 1px solid $color-footer-link-blue;
  color: #fff !important;
}
 
.button-medium-primary {
  @include c-button-base();
 
  font-size: 16px;
  padding: 16px 24px;
 
  background-color: $color-footer-link-blue;
  border: 1px solid $color-footer-link-blue;
  color: #fff !important;
}
 
.button-large-primary {
  @include c-button-base();
 
  font-size: 1.1rem;
  padding: 0.75rem 2rem;
 
  background-color: $color-footer-link-blue;
  border: 1px solid $color-footer-link-blue;
  color: #fff !important;
}
 
.button-small-secondary {
  @include c-button-base();
 
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
 
  background-color: $color-innovation;
  border: 1px solid $color-innovation;
  color: #fff !important;
}
 
.button-medium-secondary {
  @include c-button-base();
 
  font-size: 16px;
  padding: 16px 24px;
 
  background-color: $color-innovation;
  border: 1px solid $color-innovation;
  color: #fff !important;
}
 
.button-large-secondary {
  @include c-button-base();
 
  font-size: 1.1rem;
  padding: 0.75rem 2rem;
 
  background-color: $color-innovation;
  border: 1px solid $color-innovation;
  color: #fff !important;
}
 
.button-small-default {
  @include c-button-base();
 
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
 
  background-color: #fff;
  border: 1px solid $color-gray-border;
  color: $color-black-light !important;
}
 
.button-medium-default {
  @include c-button-base();
 
  font-size: 16px;
  padding: 16px 24px;
 
  background-color: #fff;
  border: 1px solid $color-gray-border;
  color: $color-black-light !important;
}
 
.button-large-default {
  @include c-button-base();
 
  font-size: 1.1rem;
  padding: 0.75rem 2rem;
 
  background-color: #fff;
  border: 1px solid $color-gray-border;
  color: $color-black-light !important;
}
 
.button-small-primary:hover,
.button-medium-primary:hover,
.button-large-primary:hover {
  text-decoration: none;
  background-color: $color-blue-dark;
  border-color: $color-blue-dark;
  color: #fff;
}
 
.button-small-secondary:hover,
.button-medium-secondary:hover,
.button-large-secondary:hover {
  text-decoration: none;
  background-color: $color-innovation-dark;
  border-color: $color-innovation-dark;
  color: #fff;
}
 
.button-small-default:hover,
.button-medium-default:hover,
.button-large-default:hover {
  text-decoration: none;
  background-color: $color-gray-border;
  border-color: $color-gray-border;
  color: #3c3c3c;
}
 
@media screen and (max-width: 915px) {
  .l-section-33 a.c-button {
    white-space: break-spaces;
  }
}
 
.btn {
  border: 1px;
  padding: 18px;
  border-radius: 2px;
  background: #f5f3f2;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
  width: 156px;
  min-height: 56px;
 
  i {
    width: 25px;
    margin-right: 18px;
    margin-left: 2px;
  }
 
  span {
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    order: 1;
    word-break: break-word;
  }
}