.c-breadcrumb {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding: 10px !important;

  .c-breadcrumb-container {
    justify-self: flex-start;
  }
}