.l-submenu {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  flex-wrap: wrap;
}

.l-submenu-wrap {
  max-width: $max-page-width;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 30px;
}

@media screen and (max-width: $bkpt-tablet-portrait-max) {
  .l-submenu-wrap  {
    display: none;
  }
}
