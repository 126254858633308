.c-submenu-list {
  padding: 0;
  margin: 0;
}

.c-submenu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  .c-submenu-link-container {
    height: 100%;
    font-weight: 300;
    display: inline-block;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-20-warm-grey;
    }

    .is-active {
      font-weight: 400;
      background-color: $color-20-warm-grey;
    }

    @include mediaSmallerThan(mobile-l) {
      display: block;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-gray;
      }

      .is-active {
        font-weight: 600;
        background-color: $color-gray;
      }

      .c-submenu-link {

        &:hover,
        &:focus,
        &:active {
          background-color: $color-gray;
        }
      }
    }
  }

  @include mediaSmallerThan(mobile-l) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    .c-submenu-link-container {
      display: flex;
      font-weight: 300;
      height: 100%;
      justify-content: space-between;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-20-warm-grey;
      }

      .is-active {
        font-weight: 300;
      }

      .first {
        display: inline-block;
      }
    }
  }
}

.c-submenu-link {
  display: block;
  font-size: 1.2rem;
  padding: 8px 20px;
  margin: 0;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background-color: $color-20-warm-grey;
  }

  @include mediaSmallerThan(mobile-l) {
    border-top: 1px solid $color-20-warm-grey;
  }

  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media (max-width: 1080px) {
    font-size: 1rem;
    padding: 11px 20px;
  }

  @media (max-width: $bkpt-mobile-landscape) {
    font-size: 1.2rem;
    padding: 10px 15px;
  }
}

@include mediaSmallerThan(mobile-l) {
  .c-submenu {
    display: none;
  }
}