// MEDIA BREAK POINTS
$bkpt-mobile-portrait: 320px;
$bkpt-mobile-landscape: 480px;
$bkpt-tablet-portrait: 768px;
$bkpt-tablet-landscape: 800px;
$bkpt-tablet: 800px;
$bkpt-desktop: 1025px;
$bkpt-tablet-portrait-max: 900px;

// MISC
$max-page-width: 1248px;
$max-inner-width: 928px;
$default-padding: 16px;
$default-margin: 16px;

// COLORS
$color-5-warm-grey: #f5f3f2;
$color-10-warm-grey: #f5f3f2;
$color-20-warm-grey: #e6e3df;
$color-30-warm-grey: #e7e0db;
$color-40-warm-grey: #cbc3bb;
$color-70-warm-grey: #a79c95;
$color-110-warm-grey: #3d3935;
$color-black-offering: #1a1a1a;
$color-service-acc-border: #d9d9d9;
$color-black: #3c3c3c;
$color-black-light: #847770;
$color-blue: #11387f;
$color-blue-disabled: #7687a5;
$color-blue-light: #1b4ea9;
$color-blue-dark: #0d2c65;
$color-innovation: #007fc8;
$color-innovation-dark: #0065a0;
$color-earth: #db9276;
$color-earth-dark: #cc6740;
$color-sun: #fdcc61;
$color-sun-dark: #fcb51b;
$color-water: #93c7c6;
$color-water-dark: #65afad;
$color-error: #d81e05;
$color-footer-link-blue: #11387f;
$color-grey: #847770;
$color-gray-border: #e6e3df;
$color-gray-dark: #cdc9c5;
$color-gray: #f5f3f2;
$color-font-blue: #4456af;
$color-yellow: #fc3;
$color-white: #fff;
$color-white-beige: #ece8e5;
$color-white-gray: #e6e3df;
$color-block-hover-gray: #f5f3f2;
$color-block-hover-gray-dark: #dcdbdb;
$color-pantone-gray: #d7d2cb;
$color-light-gray: #4d4d4d;
// ANIMATION
$durationDesktopShort: 175ms;
$durationDesktopLong: 225ms;
$transitionStandard: cubic-bezier(0.4, 0, 0.2, 1);

//CoolCalc
$color-coolcalc-lt-grey: #f9f7f6;
$color-coolcalc-dk-grey: #433d3d;
$color-coolcalc-unit: #aa998d;
$color-coolcalc-blue: #11387f;
$color-coolcalc-blue-transparent: #11387f14;
$color-coolcalc-lt-blue: #007fc8;

//Static
$header-height: 56px;
