.l-header-lite {
  width: 100%;
  height: 100%;
  position: relative;
}

.grecaptcha-badge {
  display: none !important;
}

.request-modal {
  position: fixed;
  z-index: 9999;
}

.modal-open {
  overflow: hidden;

  .request-modal {
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    height: 100vh;
  }
}

.l-header-lite-main {
  align-items: center;
  background-color: white;
  display: flex;
  height: 89px;
  justify-content: space-between;
  width: 100%;
  z-index: 500;
}

.l-header-lite-container {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: $max-page-width;
  width: 100%;

  &.flex-end {
    justify-content: flex-end;
  }

  &.mr-0 {
    margin-right: 0px;
  }
}

.l-header-lite-container-specialmenu {
  display: flex;
  flex: 1 1 50%;
  justify-content: space-between;
  width: 100%;
  height: 32px;

  .l-flex-items {
    width: auto;
    flex-grow: 1;

    &.center {
      justify-content: flex-end;
      display: flex;
      flex: 0 1 1248px;
    }

    &.right {
      background-color: $color-gray;
    }
  }
}

.l-header-lite-dropdown-container {
  max-height: calc(100vh - 88px);
  position: absolute;
  transform: translateY(-110%);
  transition-duration: 275ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  z-index: 10;

  &::-webkit-scrollbar {
    display: none;
  }

  &.show {
    transform: translateY(0%);
  }

  &.sign-in-dropdown {
    display: flex;
    margin: 0 auto;
    max-width: 1248px;
    justify-content: end;
    position: relative;
  }
}

.l-header-lite-main-mobile-item {
  display: none;
}

.l-header-lite-sticky {
  display: flex;
  flex-direction: column;
  transition: 0.2s ease-in-out all;
  width: 100%;
  position: relative;

  &.hide-top-bar {
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
  }

  &.offset-top {
    position: fixed;
    width: 100%;
    top: 0;
    display: block;
    z-index: 1000;
    transition: 0.2s ease-in-out all;
  }

  &.posRelative {
    position: relative;
  }

  &.posFixed {
    position: fixed;
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.l-header-lite-main-brand {
  display: inline-block;
  height: 40px;
  margin: -26px 65px 0 0;
  width: 135px;
}

.l-header-lite-main-left {
  display: none;
}

.l-header-lite-main-right {
  display: flex;
  flex: 1;
}

.l-header-lite-main-menu {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.l-header-lite-main-menu-left {
  display: flex;
}

.l-header-lite-main-menu-right {
  display: flex;
}

.l-header-lite-main-search {
  display: none;
}

.l-header-lite-sticky-wrapper {
  height: 89px;
}

.l-header-lite-menu-mobile-section {
  align-items: center;
  display: none;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: flex-end;

  div:last-child {
    border-left: 1px solid $color-gray;
  }
}

@media screen and (max-width: $bkpt-tablet-portrait-max) {
  .l-header-lite-dropdown-container {
    height: 100vh;
    max-height: 100vh;
    position: absolute;
    transform: translateY(-110%);
    transition: 0.225s ease-in-out animation;

    &.show {
      border-bottom: 0;
    }
  }

  .l-header-lite-main {
    height: 56px;
  }

  .l-header-lite-main-brand {
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 0;
    padding: 0;

    a {
      align-items: center;
      display: flex;
    }

    img {
      height: 30px;
      width: auto;
    }
  }

  .l-header-lite-sticky-wrapper {
    height: 56px;
  }

  .l-header-lite-main-left {
    flex: 1;
  }

  .l-header-lite-main-right {
    flex: 1;
    justify-content: flex-end;
  }

  .l-header-lite-main-menu {
    display: none;
  }

  .l-header-lite-main-mobile-item {
    display: flex;
  }

  .l-header-lite-menu-section {
    display: none;
  }

  .l-header-lite-menu-mobile-section {
    display: flex;
  }

  .l-header-lite-main-left {
    display: flex;
  }

  .l-header-lite-main-search {
    display: flex;
  }

  .l-header-lite-sticky {
    &.hide-top-bar {
      transform: translateY(0);
    }
  }

  .l-header-lite-top-bar {
    display: none;
  }
}

.reverse-header-mobile {
  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.swiper-pagination-bullet {
  background: $color-20-warm-grey !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: $color-70-warm-grey !important;
}

@media screen and (min-width: $bkpt-tablet-portrait-max) and (max-width: 1080px) {
  .l-header-lite-main-mobile-item {
    display: flex;
  }

  .search-sign-in-right {
    display: none;
  }
}
