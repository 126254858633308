.c-in-page-nav {
  background-color: $color-5-warm-grey;
  padding: 0;
  height: 0;
  position: relative;
  width: 100%;
  z-index: 100;
  visibility: hidden;

  &.visible {
    visibility: visible;
    height: 48px;
  }

  &.sticky {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    position: fixed;
  }

  &.loading {
    min-height: 48px;
    visibility: visible;
    @include c-skeleton-horizontal-loading($color-5-warm-grey);
  }

  .c-in-page-nav-swiper {
    .swiper-slide {
      width: auto;
    }
  }

  ul.c-in-page-nav-list {
    margin: 0 auto;
    max-width: 1248px;
    padding: 0 16px;

    .c-in-page-nav-list-item {
      transition: background-color $durationDesktopLong;

      &:hover {
        background-color: $color-10-warm-grey;
        color: $color-grey;
        text-decoration: none;
      }

      a {
        color: #11387f;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: 1rem;
        padding: $default-padding;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .c-in-page-nav-button {
    height: 100%;
    position: absolute;
    top: 0;
    width: 2 * $default-padding;

    &.is-next {
      background: linear-gradient(to right, transparent, $color-20-warm-grey);
      right: 0;
      z-index: 10;
      cursor: pointer;

      &:after {
        color: $color-grey;
        content: "\f105";
        display: block;
        font-family: ALicons;
        font-size: 30px;
        left: 0.5rem;
        position: absolute;
        top: 0.5rem;
      }
    }

    &.is-prev {
      background: linear-gradient(to left, transparent, $color-20-warm-grey);
      left: 0;
      z-index: 10;
      cursor: pointer;

      &:after {
        color: $color-grey;
        content: "\f105";
        display: block;
        font-family: ALicons;
        font-size: 30px;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        transform: rotate(180deg);
      }
    }
  }
}

#SearchPage {
  .c-in-page-nav {
    visibility: visible;
    height: 48px;
  }
}

span.c-in-page-nav-target {
  height: 5px;
  margin-bottom: -5px;
  z-index: 9999;
  font-size: 1px;
  visibility: hidden;
  flex-basis: 100%;
}

span.in-page-block-level {
  width: 0.1px;
}

span.in-page-block-level+.l-section {
  margin-right: -0.1px;
}