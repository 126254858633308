@mixin media($media) {
  @if $media == mobile {
    @media screen and (max-width: $bkpt-mobile-portrait) {
      @content;
    }
  } @else if $media == mobile-l {
    @media screen and (min-width: $bkpt-mobile-portrait+1) and (max-width: $bkpt-mobile-landscape) {
      @content;
    }
  } @else if $media == tablet {
    @media screen and (min-width: $bkpt-mobile-landscape+1) and (max-width: $bkpt-tablet) {
      @content;
    }
  } @else if $media == desktop {
    @media screen and (min-width: $bkpt-tablet+1) {
      @content;
    }
  }
}

@mixin mediaSmallerThan($media) {
  @if $media == mobile {
    @media screen and (max-width: $bkpt-mobile-portrait) {
      @content;
    }
  } @else if $media == mobile-l {
    @media screen and (max-width: $bkpt-mobile-landscape) {
      @content;
    }
  } @else if $media == tablet {
    @media screen and (max-width: $bkpt-tablet) {
      @content;
    }
  } @else if $media == tablet-l {
    @media screen and (max-width: $bkpt-tablet-landscape) {
      @content;
    }
  } @else if $media == desktop {
    @media screen and (max-width: $bkpt-desktop) {
      @content;
    }
  } @else if $media == mobile-menu {
    @media screen and (max-width: $bkpt-mobile-menu) {
      @content;
    }
  }
}

@mixin mediaGreaterThan($media) {
  @if $media == mobile {
    @media screen and (min-width: $bkpt-mobile-portrait+1) {
      @content;
    }
  } @else if $media == mobile-l {
    @media screen and (min-width: $bkpt-mobile-landscape+1) {
      @content;
    }
  } @else if $media == tablet {
    @media screen and (min-width: $bkpt-tablet-portrait+1) {
      @content;
    }
  } @else if $media == tablet-l {
    @media screen and (min-width: $bkpt-tablet+1) {
      @content;
    }
  }
}
