.c-hex-loadingspinner-icon{
    animation: spin 1s linear infinite;

}

@keyframes spin {
    to{
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}