table {
  color: $color-black;
  text-align: left;
  border-top: 1px solid $color-gray-border;
  border-bottom: 1px solid $color-gray-border;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  table-layout: auto;
  width: 100%;

  th {
    font-family: sans-serif;
    font-weight: bold;
    background: $color-gray-dark;
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td {
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
