.c-banner {
  display: block;
  margin: 8px;
  position: relative;
  white-space: normal;

  .c-banner-wrapper {
    .c-banner-image-container {
      .c-banner-image {
        border-radius: 3px;
      }
    }

    .c-banner-textbox {
      position: absolute;
      bottom: 24px;
      left: 16px;

      width: 50%;
      max-height: 80%;
      overflow: hidden;
      min-width: 200px;
      z-index: 0;
      border-radius: 3px 3px 22px 3px;

      .c-banner-textbox-panel {
        position: relative;
        background-color: $color-blue;

        .c-banner-textbox-header {
          padding: 8px 36px 8px 16px;
          font-size: 16px;
          line-height: 1.5;
          font-weight: 700;
          color: white;
          margin: 0;

          &:after {
            position: absolute;
            top: 0;
            right: 0;
            content: "\f105";
            font-family: ALicons;
            width: 35px;
            cursor: pointer;
            text-align: center;
            height: 100%;
            line-height: 35px;
          }
        }
      }

      .c-banner-textbox-body {
        padding: 8px 16px;
        background-color: #fff;

        .c-banner-textbox-paragraph {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
}

.slider {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: visible;
}

@include mediaSmallerThan(tablet) {
  .c-banner {
    min-width: 320px;
    a {
      text-decoration: none;
    }

    .c-banner-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .c-banner-image-container {
        flex: 1 1 100%;
      }

      .c-banner-textbox {
        flex: 1 1 100%;
        position: relative;
        left: auto;
        bottom: auto;

        .c-banner-textbox-panel {
          background-color: white;

          .c-banner-textbox-header {
            color: $color-black;
            padding-top: 0;
            padding-bottom: 0;

            &:after {
              position: relative;
              margin-left: 4px;
              line-height: inherit;
            }
          }
        }
      }
    }
  }
}
