@font-face {
  font-family: "ALicons";
  src: url("../../assets/fonts/ALIcons/ALicons.eot?urrue0");
  src: url("../../assets/fonts/ALIcons/ALicons.eot?#iefixurrue0") format("embedded-opentype"),
    url("../../assets/fonts/ALIcons/ALicons.woff?urrue0") format("woff"),
    url("../../assets/fonts/ALIcons/ALicons.ttf?urrue0") format("truetype"),
    url("../../assets/fonts/ALIcons/ALicons.svg?urrue0#ALicons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "ALicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-link:before {
  content: "\e60b";
}

.icon-envelope-o:before {
  content: "\f003";
}

.icon-envelope:before {
  content: "\f0e0";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-file:before {
  content: "\e921";
}

.icon-fax:before {
  content: "\f1ac";
}

.icon-ellipsis-v:before {
  content: "\e92b";
}

.icon-share-alt:before {
  content: "\f1e0";
}

.icon-symbol-burger:before {
  content: "\e609";
}

.icon-download:before {
  content: "\e92c";
}

.icon-chevron-up:before {
  content: "\e923";
}

.icon-chevron-right:before {
  content: "\e924";
}

.icon-chevron-left:before {
  content: "\e925";
}

.icon-chevron-down:before {
  content: "\e926";
}

.icon-Check:before {
  content: "\e606";
}

.icon-symbol-search:before {
  content: "\e602";
}

.icon-symbol-close:before {
  content: "\e604";
}

.icon-map-marker:before {
  content: "\f041";
}

.icon-phone:before {
  content: "\f095";
}

.icon-angle-left:before {
  content: "\f103";
}

.icon-angle-right:before {
  content: "\f105";
}

.icon-angle-up:before {
  content: "\f106";
}

.icon-angle-down:before {
  content: "\f107";
}

.icon-arrow-up:before {
  content: "\e60a";
}

.icon-arrow-down:before {
  content: "\e601";
}

.icon-info:before {
  content: "\e605";
}

.icon-arrow-right:before {
  content: "\e922";
}

.icon-arrow-left:before {
  content: "\ea40";
}

.icon-chevron-down-big:before {
  content: "\e927";
}

.icon-chevron-up-big:before {
  content: "\e92a";
}

.icon-chevron-left-mid:before {
  content: "\e907";
}

.icon-chevron-right-mid:before {
  content: "\e908";
}

.icon-general-enquiries:before {
  content: "\e92d";
}

.icon-partner-info:before {
  content: "\e92e";
}

.icon-product-info:before {
  content: "\e92f";
}

.icon-request-quote:before {
  content: "\e930";
}

.icon-support-tools:before {
  content: "\e931";
}

.icon-talkto-expert:before {
  content: "\e932";
}

.icon-past:before {
  content: "\e94f";
}

.icon-future:before {
  content: "\e950";
}

.icon-pdf:before {
  content: "\eadf";
}

.icon-link-ext:before {
  content: "\e935";
}

.icon-file-archive:before {
  content: "\e936";
}

.icon-file-code:before {
  content: "\e937";
}

.icon-file-excel:before {
  content: "\e938";
}

.icon-file-img:before {
  content: "\e939";
}

.icon-file-ppt:before {
  content: "\e93a";
}

.icon-file-text:before {
  content: "\e91a";
}

.icon-file-word:before {
  content: "\e93b";
}

.icon-file-empty:before {
  content: "\e93c";
}

.icon-cancel-circle:before {
  content: "\ea0d";
}

.icon-sphere:before {
  content: "\e9c9";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-linkedin:before {
  content: "\eac9";
}

.icon-plus:before {
  content: "\ea0a";
}

.icon-minus:before {
  content: "\ea0b";
}