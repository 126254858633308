.grey-5 {
  color: $color-5-warm-grey;
}
.grey-10 {
  color: $color-10-warm-grey;
}

.grey-20 {
  background-color: $color-20-warm-grey;
}

.grey-40 {
  color: $color-40-warm-grey;
}

.grey-70 {
  color: $color-70-warm-grey;
}

.grey-110 {
  color: $color-110-warm-grey;
}

.color-black {
}

.color-black-light {
}

.color-blue {
}

.color-blue-link {
}

.color-grey {
}

.color-grey-border {
}

.color-grey-dark {
}

.color-grey-light {
}

.color-grey-lighter {
}

.color-blue-font {
}

.color-yellow {
}

.color-white {
}

.color-beige {
}

.color-error {
}
