.l-header {
  width: 100%;
  height: 100%;
  min-height: 88px;
  position: relative;

  &.hide-header {
    visibility: hidden;

    .l-header-main-brand {
      visibility: visible;
    }
  }

  &.with-breadcrumbs {
    min-height: 149px;
  }

  &.with-in-page-nav {
    min-height: 136px;
  }

  &.with-in-page-nav.with-breadcrumbs {
    min-height: 198px;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.request-modal {
  position: fixed;
  z-index: 9999;
}

.modal-open {
  overflow: hidden;

  .request-modal {
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    height: 100vh;
  }
}

.l-header-top-bar {
  align-items: center;
  background-color: #fff;
  display: block;
  height: 32px;
  justify-content: flex-end;
  width: 100%;
  z-index: 2000;

  .l-header-top-bar-ribbon {
    display: flex;
    height: 6px;
    justify-content: flex-start;
    background-color: $color-gray;
    width: 100%;
    position: absolute;
  }
}

.l-header-main {
  align-items: center;
  background-color: white;
  display: flex;
  height: $header-height;
  justify-content: space-between;
  width: 100%;
  z-index: 500;
}

.l-header-container {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: $max-page-width;
  width: 100%;

  &.flex-end {
    justify-content: flex-end;
  }

  &.mr-0 {
    margin-right: 0px;
  }

  &.loading {
    .l-header-main-menu {
      @include c-skeleton-horizontal-loading($color-gray);
      height: 42px;
      border-radius: 12px;
    }
  }
}

.l-header-container-specialmenu {
  display: flex;
  flex: 1 1 50%;
  justify-content: space-between;
  width: 100%;
  height: 32px;

  .l-flex-items {
    width: auto;
    flex-grow: 1;

    &.center {
      justify-content: flex-end;
      display: flex;
      flex: 0 1 1248px;
    }

    &.right {
      background-color: $color-gray;
    }
  }
}

.l-header-dropdown-container {
  max-height: calc(100vh - 88px);
  position: absolute;
  transform: translateY(-110%);
  transition-duration: 275ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  z-index: 10;

  &::-webkit-scrollbar {
    display: none;
  }

  &.show {
    transform: translateY(0%);
  }

  &.sign-in-dropdown {
    display: flex;
    margin: 0 auto;
    max-width: 1248px;
    justify-content: end;
    position: relative;
  }
}

.l-header-main-mobile-item {
  display: none;
}

.l-header-sticky {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &.hide-top-bar {
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
  }

  &.offset-top {
    position: fixed;
    width: 100%;
    top: 0;
    display: block;
    z-index: 1000;
  }

  // Added to handle content shift when we open any dropdown from header
  &.adjust-width {
    width: calc(100% - var(--scrollbar-width));
  }

  &.posRelative {
    position: relative;
  }

  &.posFixed {
    position: fixed;
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.l-header-sticky-wrapper {
  height: 88px;
}

.l-header-conditional-sticky-wrapper {
  height: 149px;
}

.l-header-main-brand {
  display: inline-block;
  height: 45px;
  margin: -26px 65px 0 0;
  width: 135px;
}

.l-header-main-left {
  display: none;
}

.l-header-main-right {
  display: flex;
  flex: 1;
}

.l-header-main-menu {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;

  &.loading {
    @include c-skeleton-horizontal-loading($color-gray);
    height: 42px;
    border-radius: 12px 0 0 12px;
  }
}

.l-header-main-menu-left {
  display: flex;
}

.l-header-main-menu-right {
  display: flex;
}

.l-header-main-search {
  display: none;
}

.l-header-menu-mobile-section {
  align-items: center;
  display: none;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: flex-end;

  div:last-child {
    border-left: 1px solid $color-gray;
  }
}

@media screen and (max-width: $bkpt-tablet-portrait-max) {
  .l-header {
    min-height: $header-height;

    &.with-breadcrumbs {
      min-height: 118px;
    }

    &.with-in-page-nav {
      min-height: 104px;
    }

    &.with-in-page-nav.with-breadcrumbs {
      min-height: 166px;
    }
  }

  .l-header-dropdown-container {
    height: 100vh;
    max-height: 100vh;
    position: absolute;
    transform: translateY(-110%);
    transition: 0.225s ease-in-out animation;

    &.show {
      border-bottom: 0;
      min-width: max-content;
    }
  }

  .l-header-main {
    height: $header-height;
  }

  .l-header-main-brand {
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 0;
    padding: 0;

    a {
      align-items: center;
      display: flex;
    }

    img {
      height: 30px;
      width: auto;
    }
  }

  .l-header-main-left {
    flex: 1;
  }

  .l-header-main-right {
    flex: 1;
    justify-content: flex-end;
  }

  .l-header-main-menu {
    display: none;
  }

  .l-header-main-mobile-item {
    display: flex;
  }

  .l-header-menu-section {
    display: none;
  }

  .l-header-menu-mobile-section {
    display: flex;
  }

  .l-header-main-left {
    display: flex;
  }

  .l-header-main-search {
    display: flex;
  }

  .l-header-sticky {
    &.hide-top-bar {
      transform: translateY(0);
    }
  }

  .l-header-sticky-wrapper {
    height: $header-height;
  }

  .l-header-conditional-sticky-wrapper {
    height: 118px;
  }

  .l-header-top-bar {
    display: none;
  }
}

@media screen and (min-width: $bkpt-tablet-portrait-max) and (max-width: 1080px) {
  .l-header-main-mobile-item {
    display: flex;
  }
  .search-sign-in-right {
    display: none;
  }
  .mobile-right-icon {
    width: 35px !important;
    padding: 0px 16px 0px 0px !important;
  }
}

@media screen and (max-width: $bkpt-tablet-portrait-max) {
  .sign-in-area,
  .l-sign-in-area-container,
  .market-area {
    display: none;
  }
}

.reverse-header-mobile {
  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.swiper-pagination-bullet {
  background: $color-20-warm-grey !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: $color-70-warm-grey !important;
}
