.c-hero-banner {
  height: 480px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.big-article-image {
    height: 400px;
    background-size: auto 100%;
  }

  img {
    height: 450px;
    object-fit: cover;
    background-size: cover;
  }

  .c-hero-banner-image img,
  .c-hero-banner-desktop img {
    height: 480px;
  }
}

.c-hero-banner-desktop,
.c-hero-banner-image {
  background: no-repeat 50%;
  height: 480px;
  width: 100%;
  object-fit: cover;
  background-size: cover;
}

.c-hero-banner-center {
  max-width: auto;
}

.c-hero-banner-card-button-container {
  display: flex;
  flex-direction: row;

  &.button-container-left {
    justify-content: flex-start;
  }

  &.button-container-center {
    justify-content: center;
  }

  &.button-container-right {
    justify-content: flex-end;
  }
}

.c-hero-banner-card-button {
  margin: 25px 35px;
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem 1rem;
  border-radius: 3px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;

  &.button-primary {
    background-color: $color-blue;
    color: white;
    border: 1px solid $color-blue;
  }

  &.is-mobile {
    display: none;
  }
}

.c-hero-banner-content {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.c-hero-banner-card {
  position: absolute;
  z-index: 0;
  top: 7%;
  overflow: hidden;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 22px;
  border-top-right-radius: 3px;
  max-height: 500px;
  max-width: 400px;
  white-space: normal;

  &.c-hero-banner-card-left {
    left: 20px;
  }

  &.c-hero-banner-card-right {
    right: 20px;
  }

  &.c-hero-banner-card-primary {
    background-color: $color-blue;

    .c-hero-banner-card-header {
      color: $color-white;
    }

    .c-hero-banner-card-subtitle {
      color: $color-white;
    }

    .c-hero-banner-card-paragraph {
      color: $color-white;
    }
  }

  &.c-hero-banner-card-light {
    background-color: $color-gray;

    .c-hero-banner-card-header {
      color: $color-black;
    }

    .c-hero-banner-card-subtitle {
      color: $color-black;
    }

    .c-hero-banner-card-paragraph {
      color: $color-black;
    }
  }

  .c-hero-banner-card-header {
    font-size: 3rem;
    padding: 2rem 2rem 1rem;
    
    &.header-as-p-tag {
      line-height: normal;
    }
  }

  .c-hero-banner-card-subtitle {
    margin: 0;
    padding: 5px 35px;
    font-size: 1.6rem;
  }

  .c-hero-banner-card-paragraph {
    padding: 10px 35px;
  }

  .c-hero-banner-card-button-container {
    display: flex;
    flex-direction: row;

    &.button-container-left {
      justify-content: flex-start;
    }

    &.button-container-right {
      justify-content: flex-end;
    }

    .c-hero-banner-card-button {
      margin: 25px 35px;
      font-size: 1rem;
      line-height: 1rem;
      padding: 1rem 1rem;
      border-radius: 3px;
      box-sizing: border-box;
      text-decoration: none;
      text-align: center;

      &.button-primary {
        background-color: $color-blue;
        color: white;
        border: 1px solid $color-blue;
      }

      &.button-light {
        background-color: white;
        color: $color-blue;
        border: 1px solid $color-20-warm-grey;
      }
    }
  }
}

.c-hero-banner-card-center {
  text-align: center;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);

  p {
    text-align: center;
  }
}

@include mediaSmallerThan(mobile-l) {
  .c-hero-banner-card {
    padding-bottom: 1rem !important;
  }

  .c-hero-banner-image img {
    display: none;
  }
}

@include mediaSmallerThan(tablet) {
  .c-hero-banner-card-center {
    max-width: 100%;
    left: 0;
    transform: translateX(0);
  }

  .c-hero-banner-card {
    position: relative;
  }

  .c-hero-banner img {
    display: none;
  }

  .c-hero-banner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    overflow: auto;

    .c-hero-banner-image {
      height: 484px;
    }

    .c-hero-banner-image img {
      flex: 1 1 100%;
      display: none;

      &.is-mobile {
        display: inherit;
      }

      &.is-desktop {
        display: none;
      }
    }

    .c-hero-banner-desktop {
      display: none !important;
    }

    .hero-img {
      display: block;
    }

    .c-hero-banner-content {
      background-color: $color-blue;
      position: relative;
    }

    .c-hero-banner-card {
      margin: auto;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      max-width: 100%;
      border-radius: 0;

      &.c-hero-banner-card-light {
        background-color: $color-blue;

        .c-hero-banner-card-header {
          color: $color-white;
        }

        .c-hero-banner-card-subtitle {
          color: $color-white;
        }

        .c-hero-banner-card-paragraph {
          color: $color-white;
        }
      }

      &.c-hero-banner-card-left {
        width: auto;
        left: 0;
      }

      .c-hero-banner-card-header {
        text-align: center;
        font-size: 1.5rem;
        padding: 20px 25px 10px;
      }

      .c-hero-banner-card-paragraph {
        padding: 10px 25px;
        font-size: 0.9rem;
      }

      .c-hero-banner-card-button-container {
        .c-hero-banner-card-button {
          flex: 1;
          margin: 0 25px;
          padding: 0.8rem 1rem;

          &.button-primary {
            background-color: white;
            color: $color-blue;
            border: 1px solid $color-20-warm-grey;
          }
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .c-hero-banner-image img {
    flex: 1 1 100%;
    display: none;

    &.is-mobile {
      display: none !important;
    }
  }
}