.c-story-filter {
  background-color: $color-gray;
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 20px;
  width: 100%;

  .c-story-filter-filters {
    display: flex;
    flex: 6;

    & > div {
      flex: 1;
      margin-right: 10px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .c-story-filter-button {
    display: flex;
    flex: 4;
    justify-content: flex-end;
    align-items: center;

    & > div {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 1050px) {
    display: block;

    .c-story-filter-filters {
      flex-direction: column;
      display: block;

      & > div {
        flex: 1;
        margin-right: 0;
      }
    }

    .c-story-filter-button {
      display: flex;
      flex: 4;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      margin-top: 20px;

      button {
        width: 100%;
      }

      & > div {
        margin-left: 0;
        padding: 10px 0;
        width: 100%;
      }
    }
   
  }
}


