.l-share-buttons-container {
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: flex-start;
  align-items: center;
  color: $color-grey;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -ms-flex-align: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 12px;
  -webkit-justify-content: center;
  justify-content: center;

  .l-share-buttons-container-links {
    display: inline-flex;
    flex-direction: row;
  }

  @include media(mobile) {
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem;
  }
  @include media(mobile-l) {
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem;
  }
  @include media(tablet) {
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem 0px 0px;
  }

  .share {
    color: $color-grey;
    font-size: 12px;
    font-weight: 400;
  }

  .follow {
    color: #D7D2CB;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    line-height: 1.75;
  }

  *::-ms-backdrop,
  .follow {
    width: auto;
  }
}
.l-share-buttons-container-links:hover .c-share-link a {
  opacity: 0.5;
}

.l-share-buttons-container-links:hover .c-share-link a:hover {
  opacity: 1;
}
