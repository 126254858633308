$block-name: c-news-block;

.c-news-block {
  padding: 10px 20px;
  display: block;

  &:hover {
    text-decoration: none;
    background-color: $color-block-hover-gray;
  }

  .#{$block-name}-container {
    display: flex;
    flex-direction: row;
  }

  .#{$block-name}-thumbnail {
    flex: 1 1 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin-right: 20px;
    border-radius: 3px;
  }

  .#{$block-name}-content {
    flex: 1 1 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    flex: 2 1 0;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 0;
  }

  &.#{$block-name}-vertical {
    .#{$block-name}-container {
      flex-wrap: wrap;
    }

    .#{$block-name}-thumbnail {
      margin-right: 0;
      flex-basis: 100%;
    }

    .#{$block-name}-content {
      flex-basis: 100%;
    }
  }

  .#{$block-name}-list-image {
    width: 100%;
    display: block;
    border-radius: 3px;
  }

  .#{$block-name}-date-text {
    display: inline-block;
    padding: 0;
    line-height: 1.5;
    color: $color-black-light;
    font-size: 0.9rem;
  }

  .#{$block-name}-title-text {
    @extend .c-news-heading-text;
    padding: 0;
    margin: 0;
    font-size: 1.05rem;
    line-height: 1.3;
    font-weight: 600;
    color: #3c3c3c;
    word-break: break-word;
  }

  .#{$block-name}-content-text {
    font-size: 1rem;
    line-height: 1.5;
    color: $color-black-light;
    word-break: break-word;
  }

  .hide-in-desktop {
    display: none;
  }
}

.c-news-list-block-li {
  border-bottom: 1px solid $color-gray;
}

@include mediaSmallerThan(tablet) {
  .#{$block-name} {
    &.#{$block-name}-vertical {
      border-bottom: 1px solid $color-gray;

      .#{$block-name}-container {
        flex-wrap: nowrap;
      }

      .#{$block-name}-thumbnail {
        margin-right: 20px;
        flex: 1 1 0;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;

        img {
          width: 100%;
        }
      }

      .#{$block-name}-content {
        flex: 2 1 0;
        flex-grow: 2;
        flex-shrink: 1;
        flex-basis: 0;
      }
    }

    .hide-in-desktop {
      display: block;
    }

    &.is-latest-news {
      .#{$block-name}-title-text {
        font-size: 1.1rem;

        &:after {
          content: "\f105 ";
          font-family: "ALicons";
          position: absolute;
          margin-left: 7px;
        }
      }
    }

    .#{$block-name}-content-text {
      display: none;
    }
  }
}

.c-news-heading-text {
  margin: 5px 0;
}