.c-accordion-tabs {
  .c-accordion-tabs-wrapper {
    .c-accordion-tabs-head {
      padding: 1.125rem 1rem;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      background-color: $color-blue;
      border-bottom: 1px solid #182458;
      color: white;

      @media screen and (min-width: 768px) {
        .is-bound {
          padding: 0 !important;
        }
      }

      &.c-accordion-tabs-is-open {
        .c-accordion-tabs-head-icon {
          transform: rotate(180deg);
        }
      }

      &.c-accordion-tabs-head-gray {
        background-color: $color-gray;
        color: $color-110-warm-grey;
        border: 0;

        h4 {
          color: inherit;
          font-size: 1.7rem;
        }

        i.c-accordion-tabs-chevron-down {
          color: inherit;
        }
      }

      &.c-accordion-tabs-head-countryselect {
        background-color: $color-gray-border;
        color: $color-white;
        border: none;
        padding: 20px;

        h1 {
          font-size: 1rem;
          color: $color-white;
          font-family: Roboto;
          font-weight: 400;
        }
      }

      &.c-accordion-tabs-head-light {
        background-color: white;
        border-bottom: 1px solid $color-20-warm-grey;

        *::-ms-backdrop,
        .c-accordion-tabs-chevron-down {
          background-size: 20px 12px !important;
          height: 12px !important;
          width: 20px !important;
        }

        .c-accordion-tabs-chevron-down {
          background-size: 24px 24px;
          display: inline-block;
          height: 24px;
          width: 24px;
          font-family: "ALicons";
          font-size: 12px;
          font-style: normal;

          &:before {
            content: "\e927";
          }
        }

        &.c-accordion-tabs-is-open {
          border-bottom: none;
          border-top: 3px solid $color-20-warm-grey;
          background-color: $color-5-warm-grey;
        }

        &.no-background {
          background-color: transparent;
        }

        &:hover {
          background-color: $color-5-warm-grey;
        }

        .c-accordion-tabs-head-title,
        .c-accordion-tabs-head-icon {
          color: #141414;
        }
      }

      .c-accordion-tabs-head-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: $default-padding;
        padding-right: $default-padding;

        @media screen and (max-width: 580px) {
          padding-left: $default-padding;
        }
      }

      .c-accordion-tabs-head-title-icon {
        max-width: 40px;
        flex: 1;
      }

      .c-accordion-tabs-head-title {
        font-size: 20px;
        line-height: 1;
        color: white;
        margin-top: 0px;

        @media screen and (max-width: 580px) {
          font-size: 1.5rem !important;
        }
      }

      .c-accordion-tabs-head-icon {
        align-self: center;
        transition: transform 0.2s;
      }

      .c-accordion-tabs-chevron-down {
        display: inline-block;
        font-family: "ALicons";
        font-size: 12px;
        font-style: normal;
        color: #fff;

        &:before {
          content: "\e927";
        }
      }
    }

    .c-accordion-tabs-body {
      background-color: transparent;
      height: 0;
      opacity: 1;
      overflow: hidden;
      width: 100%;

      &.c-accordion-tabs-is-open {
        height: auto;
        opacity: 1;
        overflow: visible;
      }
    }

    &.c-offices {
      .c-accordion-tabs-head {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &.is-related,
  .is-documents {
    .c-accordion-tabs-wrapper {
      .c-accordion-tabs-head {
        background-color: $color-grey;
        border-bottom: none;
        transition: background-color $durationDesktopLong;
        border-bottom: 1px solid $color-white;
        -webkit-tap-highlight-color: rgba(98, 85, 72, 0.27);

        &:hover {
          background-color: #807363;
        }

        &.c-tabs-RelatedProducts {
          background-color: $color-water-dark;
          border: 0;
          cursor: inherit;

          h4 {
            color: $color-blue-dark;
            font-size: 2rem;
          }
        }

        &.c-tabs-Documents {
          background-color: $color-sun;
          border: 0;
          cursor: inherit;

          h4 {
            color: $color-blue-dark;
            font-size: 2rem;
          }
        }

        &.c-tabs-RelatedServices {
          background-color: $color-earth;
          border: 0;
          cursor: inherit;

          h4 {
            color: $color-blue-dark;
            font-size: 2rem;
          }
        }

        &.c-tabs-RelatedIndustries {
          background-color: $color-40-warm-grey;
          border: 0;
          cursor: inherit;

          h4 {
            color: $color-blue-dark;
            font-size: 2rem;
          }
        }

        &.c-tabs-RelatedApplications {
          background-color: $color-40-warm-grey;
          border: 0;
          cursor: inherit;

          h4 {
            color: $color-blue-dark;
            font-size: 2rem;
          }
        }

        .c-accordion-tabs-head-icon {
          transform: rotate(-90deg);
          color: $color-blue-dark;
          font-size: 0.5em;
        }

        &.c-accordion-tabs-is-open {
          .c-accordion-tabs-head-icon {
            transform: rotate(0deg);
            color: $color-blue-dark;
          }
        }
      }
    }
  }

  &.is-documents {
    .c-accordion-tabs-wrapper {
      background-color: $color-white;
      padding-left: $default-padding;
      padding-right: $default-padding;

      .c-accordion-tabs-is-open {
        background-color: $color-white;
      }

      .c-document-list-item.has-line-under {
        border-bottom: solid 1px $color-10-warm-grey;
      }

      .c-accordion-tabs-head {
        padding-left: 0;
        padding-right: 0;
        background-color: $color-white;
        border-bottom: 1px solid $color-10-warm-grey;

        &:hover {
          background-color: $color-white;
        }

        .c-accordion-tabs-head-container {
          .c-accordion-tabs-head-title {
            flex: 2;
            color: $color-110-warm-grey;
          }

          .c-accordion-tabs-head-icon {
            font-family: "ALicons";
            font-size: 12px;
            font-style: normal;
            color: $color-110-warm-grey;

            &:before {
              content: "\e927";
              color: $color-grey;
            }
          }
        }
      }
    }
  }
}