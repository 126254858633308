.c-product-document-block {
    .c-filter-block {
        height: 100%;
        .is-bound {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: stretch;
            align-items: stretch;
            flex: 1 0 100%;
            .c-filter-block-tag-list {
                flex: 0 1 75%;
                order: 0;
                padding: 0.5rem 1rem 0.5rem 1rem;
                span {
                    cursor: pointer;
                }
            }
            .c-filter-block-search-bar {
                flex: 0 1 25%;
                padding: 0.5rem 1rem 0.5rem 1rem;
                align-self: center;
                .c-filter-block-search-input {
                    position: relative;
                    height:2.5rem;
                    input {
                        color: $color-grey;
                        font-size: 1rem;
                        height: 2rem;
                        line-height: 1.5rem;
                        box-shadow: none;
                        border-width: 1px;
                        border-style: solid;
                        border-color: $color-70-warm-grey;
                        border-image: initial;
                        padding: 1rem;
                        height:100%;
                        padding: 0px 5px 0px 0.5rem;
                    }
                    i.icon-symbol-close {
                        color:$color-grey;
                        float: right;
                        font-size: 0.75rem;
                        font-weight: 600;
                        padding-right: 8px;
                        position: absolute;
                        transition: all 0.3s ease-in-out;
                        right: 0px;
                        top: 46%;
                        display: none;
                    }
                }
                @media screen and (max-width: 580px) {
                    align-self: stretch;
                } 
            }
            .l-products-list-container {
                padding: 1rem;
                flex-basis: 100%;
                .l-products-list {
                    .c-document-list-item {
                        padding-left: 0px;
                        display: none;
                    }
                }
                .l-document-no-result {
                    padding:0.5rem;
                    display: none;
                }
                .l-show-more-btn{
                    .c-button {
                        background-color: $color-gray;
                        border: 1px solid $color-gray;
                        color: $color-grey;
                        padding: 12px 32px 12px 32px;
                        border-radius: 0 0 3px 3px;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
            }

            &.mobile {
                @media screen and (max-width: 580px) {
                    flex-direction: column;
                }
            }
        }
        @media screen and (max-width: 580px) {
            padding-left: 0;
        }           
    }
}