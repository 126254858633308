.l-products-list-container {
  padding-bottom: 24px;
}
.l-products-list {
  @extend .l-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  margin: auto;

  .l-products-list-item {
    flex: 0 1 25%;
    min-width: 312px;

    &.is-4 {
      &:hover {
        background-color: #F1EFED;
        border-radius: 3px;
        cursor: pointer;
      }
      &:hover .is-style-3 .c-related-card-container {
        background-color: #F1EFED;
      }
    }
  }
}
.l-products-list-item-new {
  display: flex;
}
.l-section-25 .l-products-list-item-new {
  display: block;
}
.l-section-25 .l-products-list-item-new .align-products {
  margin: 0;
}
.l-section-25 .l-products-list-item-new .c-related-card .align-product-container {
  min-width: auto;
}
@include mediaSmallerThan(mobile-l) {
  .l-products-list {
    .l-products-list-item {
      flex: 1 1 100%;
    }
  }
}

@include mediaSmallerThan(tablet) {
  .l-products-list {
    justify-content: center;

    button {
      width: 100%;
      margin: 0 1rem;
    }
  }
}
