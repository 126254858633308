.l-flex-container {
  display: flex;
  flex-wrap: wrap;
  &.items-center {
    justify-content: center;
    align-items: center;
  }
}

.l-flex-container-row {
  flex-direction: row;
}

.l-flex-container-col {
  flex-direction: column;
}
img#LiveMonTransparentImage {
  display: none;
}