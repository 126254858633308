.c-enquiry-type-card {
  box-sizing: border-box;
  display: block;
  position: relative;
  &:hover {
    background-color: #f1efed;
    text-decoration: none;
  }
  h4 {
    color: #3d3935;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin: 0px;
    word-break: break-all;
  }
  p {
    color: #3d3935;
    font-size: 14px;
    line-height: 1.71;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .c-enquiry-type-card-container {
    padding: 1rem 1rem 1rem 1rem;
    background-color: #f1efed;
    border-radius: 3px;
    text-align: center;
    height: 190px;
    display: flex;
    flex-direction: column;
    transition: background-color 0.5s ease;
    &:hover {
      background-color: $color-40-warm-grey;
    }
  }
  .c-enquiry-type-card-more-btn {
    align-items: center;
    color: #847770;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 24px;
    background-color: #847770;
    border-radius: 50%;
    &:before {
      content: "\e929";
      color: #fff;
      font-family: "ALicons";
    }
  }
  .c-enquiry-type-card-text {
    margin-top: 0.5rem;
  }
  .c-enquiry-type-card-icon {
    i {
      font-size: 5.5rem;
      color: navy;
      transform: translateY(0px);
      transition: all 0.6s;
    }
  }
}
