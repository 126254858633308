.desktop .content::after,
.desktop .content::before {
  content: "";
  display: table;
}

.select-region {
  background-color: #424245;
  padding-bottom: 1.4rem;
  padding-top: 0.5rem;
  text-align: center;
  width: 100%;
  position: fixed;
  z-index: 12;
  
  @include mediaSmallerThan(desktop) {
    padding-top: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
	.select-region {
    padding-right: 2rem;
	}
}

@media screen and (min-width: 1025px) {
	.select-region {
    padding-bottom: 1rem;
	}
}

.select-region-text {
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.4rem;
  margin-top: 0.25rem;
  max-width: 70rem;
  font-size: 0.9rem;
  text-align: center;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.select-region-text-narrow {
  max-width: 38rem;
}

.select-region-heading-text {
  color: #fff;
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0.875rem;
  margin-top: 5px;
  max-width: 70rem;
  font-size: 1.5rem;
  text-align: center;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.select-region-remember-container {
  color: #fff;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.9375rem;
  max-width: 50rem;
  width: 100%;
  @include mediaSmallerThan(desktop) {
    margin-top: 1.25rem;
  }
}

.mobile .select-region-remember-container {
  margin-top: 1.25rem;
}

.select-region-close {
  -webkit-transition: all 0.2s ease;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.9375rem;
  position: absolute;
  right: 0px;
  top: 0px;
  transition: all 0.2s ease;
  @include mediaSmallerThan(desktop) {
    top: 0px;
  }
}

.mobile .select-region-close {
  top: 0px;
}

.select-region-button {
  -moz-box-sizing: border-box;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  -webkit-user-select: none;
  background-color: #fff;
  border: 1px solid $color-gray;
  border-radius: 3px;
  box-sizing: border-box;
  color: $color-blue;
  display: inline-block;
  font-size: 0.8125rem;
  line-height: 1rem;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  outline: none;
  padding: 0.3125rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  user-select: none;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
  &.button-disabled,
  &:disabled {
    background-color: #fff;
    border-color: $color-gray;
    color: $color-gray-border;
    cursor: initial;
  }
  &:hover {
    background-color: $color-gray-border;
    border-color: $color-gray-border;
    color: $color-blue;
  }

  @include mediaSmallerThan(tablet) {
    margin-bottom: 0.8rem;
    width: 44%;
  }
}

.select-region-button-secondary {
  -moz-box-sizing: border-box;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  -webkit-user-select: none;
  background-color: $color-blue;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff !important;
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  font-size: 0.8125rem;
  line-height: 1rem;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  outline: none;
  padding: 0.3125rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  user-select: none;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    border: 1px solid #fff;
    color: #fff;
  }
  &.button-disabled,
  &:disabled {
    background-color: #ece8e5;
    border-color: $color-gray-border;
    color: #fff;
    cursor: initial;
  }
}

@media screen and (max-width: 400px) {
  .select-region-button-secondary,
  .select-region-button {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 0.625rem;
    width: 80%;
  }
}

.select-region-checkbox-container {
  display: inline-block;
  height: 1em;
  position: relative;
  input {
    cursor: pointer;
  }
}
@include media(mobile) {
  .select-region-checkbox-container .input-check-mark {
    font-size: 0.8125rem;
    left: 2.2864px;
    line-height: 20.5776px;
    top: -2.2864px;
    vertical-align: baseline;
    position: absolute;
    color: $color-blue;
  }
}

.select-region-checkbox {
  border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  width: 1.125rem;
  height: 1.125rem;
  font-size: 0.9375rem;
}

.select-region-label {
  cursor: pointer;
  margin-left: 0.3125;
  color: #fff;
}

.not-touch .select-region-close:hover {
  background-color: rgba(255, 255, 255, 0.09);
  text-shadow: 0px 0px 1px #fff;
}

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

.input-hidden {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.input-hidden + .input-radio > .input-radio-dot,
.input-hidden + .input-checkbox > .input-check-mark {
  display: none;
}
.input-hidden:checked + .input-checkbox > .input-check-mark {
  display: inline-block;
}
.input-hidden:disabled + .input-checkbox > .input-check-mark {
  color: $color-blue;
  position: absolute;
  left: 0.1rem;
}
.input-hidden:checked + .input-checkbox > .input-check-mark {
  display: inline-block;
}

.input-hidden:checked + .input-checkbox > .input-check-mark {
  color: $color-blue;
  position: absolute;
  left: 0.1rem;
}

.wrap-content-banner{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 400px){
  .select-region-button-secondary, .select-region-button {
    width: 90%;
}
}
