$padding: 0, 8, 16, 32, 48;
$borders: 1, 2, 3, 4, 5;

@each $i in $padding {
  .p-#{$i} {
    padding: #{$i}px;
  }

  .pt-#{$i} {
    padding-top: #{$i}px;
  }

  .pr-#{$i} {
    padding-right: #{$i}px;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }

  .pl-#{$i} {
    padding-left: #{$i}px;
  }
}

@each $i in $borders {
  .b-#{$i} {
    border: #{$i}px solid $color-gray;
  }

  .bt-#{$i} {
    border-top: #{$i}px solid $color-gray;
  }

  .br-#{$i} {
    border-right: #{$i}px solid $color-gray;
  }

  .bb-#{$i} {
    border-bottom: #{$i}px solid $color-gray;
  }

  .bl-#{$i} {
    border-left: #{$i}px solid $color-gray;
  }
}
