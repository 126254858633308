* {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
    background-color: #F5F3F2;
    }
    .service_title{
    padding: 6px 6px 6px 0;
    display: inline-block;
    font-size: 14px;
    line-height: 12px;
    font-weight: bold;
    color: #545151;
    }
    .service_checkbox{
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
    }
  .service_opt{
    padding: 6px 6px 6px 0;
    display: inline-block;
    font-size: 14px;
    line-height: 12px;
    font-weight: normal;
    color: #545151;
  }
  .d-captcha-section{
    float: left;
    height:10px; 
    width:30px
  }
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .dateBox{
    background-color: $color-20-warm-grey;
    pointer-events: none;
  }
  .d-form-success-message{
    background-color: #f5f3f2;
    font-size: 16px;
    padding: 1.1rem;
    margin: 1rem 3.5rem;
    display: flex;
  } 
  .d-form-failure-message {
    background-color: #f5f3f2;
    color: red;
    font-size: 16px;
    padding:1.5rem 1.3rem 0 0.8rem;
  }
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      padding: 0px;
    }
  }
  
    .d-formTextBox{
    resize: vertical;
    border: 1px solid #E6E3DF;
    border-radius: 0.4rem;
    box-sizing: border-box;
    color: #3c3c3c;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 0.7rem 0.9rem;
    transition: border-color 0.1s ease-out;
    width: 100%;
  }
  .digi-label{
    display: block !important;
    font-size: 16px;
    line-height: 1.7;
    min-width: 15%;
    padding: 0;
    vertical-align: top;
  }
 
  
  .d-btn-submit{
     -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;
    background-color: #11387F;
    border: 1px solid #11387F;
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    font-family: sans-serif;
    font-size: 16px;
    margin: 15px 20px 0 0;
    outline: none;
    padding: 1.1rem 1.9rem;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
    white-space: nowrap;
    width: auto;
    cursor: pointer;
  }
  .d-btn-clear{
    background-color:#213353;
    border: 1px solid #213353;
    color: #fff;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    font-family: sans-serif;
    font-size: 16px;
    margin: 15px 20px 0 0;
    outline: none;
    padding: 1.1rem 1.9rem;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
    white-space: nowrap;
    width: auto ;
  }
   
  .container {
    background-color: #F5F3F2;
    padding: 20px;
    width: 90%;
    margin: auto;
      }
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }

  .d-float-right{
    float: right;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .col-25, .col-75{
      width: 100%;
      margin-top: 0;
    }
    .container{
    width:100%;
    }
  }
  

  @media screen and (max-width: 768px){
  
  }