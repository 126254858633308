// NewsRoom.scss

:root {
  --news-room-grid-gutter: 16px;
  --news-room-grid-margin: 32px;
  --news-room-padding: 16px;
  --news-room-margin: 8px;
  --news-room-flex-inner-gap-small: 16px;
  --news-room-flex-inner-gap-heading: calc(2 * var(--news-room-padding));
  --news-room-flex-inner-gap: calc(3 * var(--news-room-padding));
}

#news-room {
  padding: var(--news-room-grid-margin);

  .l-content {
    row-gap: var(--news-room-grid-gutter);
  }

  .l-section.l-section-100.l-section-padding {
    padding: 16px 0;
  }
}

.news-room-view-all {
  color: $color-blue;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: right;
  transition: all .225s ease-in-out;

  span {
    display: flex;
    align-items: center;

    white-space: nowrap;

    gap: 4px;
  }

  &:visited {
    color: $color-blue;
  }

  &:hover {
    color: $color-blue-dark;
    text-decoration: none;
  }
}

.news-room-section-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 0 0 var(--news-room-padding) 0;
}

.news-room-financial-news-block .news-room-section-header {
  padding: var(--news-room-padding) 0;
}

@media screen and (min-width: $bkpt-desktop) {
  .news-room-latest-stories-block .news-room-section-header {
    padding: var(--news-room-padding) 0;
  }
}

@media screen and (min-width: $bkpt-mobile-landscape) and (max-width: $bkpt-tablet-portrait) {
  .news-room-latest-stories-block .news-room-section-header {
    padding: calc(2 * var(--news-room-padding)) 0;
  }
}

@media screen and (max-width: $bkpt-mobile-landscape) {
  .news-room-latest-stories-block .news-room-section-header {
    padding: var(--news-room-padding) 0;
  }

  .news-room-financial-news-block .news-room-section-header {
    padding: calc(2 * var(--news-room-padding)) 0;
  }
}

.news-room-section-title {
  color: $color-110-warm-grey;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: $bkpt-desktop) {
  :root {
    --news-room-flex-inner-gap: calc(2 * var(--news-room-padding));
  }
}

@media screen and (max-width: $bkpt-tablet-portrait) {
  :root {
    --news-room-grid-margin: 16px;
  }
}

// NewsBlock.scss
.news-block-container {
  margin-top: var(--news-room-padding);

  img {
    height: fit-content;
  }
}

.news-block {
  display: flex;
  gap: var(--news-room-flex-inner-gap);

  &.right-image {
    flex-direction: row-reverse;
  }
}

.news-block.top-image {
  gap: var(--news-room-flex-inner-gap-small);
  flex-direction: column;

  .news-block-image {
    flex-basis: 100%;
    width: 100%;
  }

  .news-block-content {
    flex-basis: 100%;
    width: 100%;
  }
}

.news-block:not(:first-child) .top-image {
  padding-top: var(--news-room-padding);
}

.news-block-image {
  display: flex;
  flex-basis: 50%;
  width: 50%;

  a {
    display: flex;
  }
}

.news-block-content {
  display: flex;
  flex-basis: 50%;
  width: 50%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.news-block-topic {
  color: $color-blue;
}

.news-block-title {
  line-height: 32px;
  margin: var(--news-room-margin) 0;
  padding: 0;
}

.news-block-description {
  color: $color-110-warm-grey;
  margin-bottom: var(--news-room-margin);
  word-break: break-word;

}

.news-block-title,
.news-block-description {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}


.news-block-date {
  color: $color-110-warm-grey;
}

@media screen and (max-width: $bkpt-desktop) {
  .news-block-title {
    font-size: 20px;
    line-height: 140%;
  }
}

@media (max-width: $bkpt-tablet-portrait) {

  //ask for 900 px instead of 768    
  .news-block {
    flex-direction: column !important;
    gap: var(--news-room-flex-inner-gap-small);
  }
}

//Layout.scss
.layout-1-col {
  display: grid;
  grid-template-columns: 1fr;
}

.layout-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.layout-3-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.layout-4-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: $bkpt-tablet-portrait) {

  .layout-4-col,
  .layout-3-col,
  .layout-2-col {
    display: flex;
    flex-direction: column;
  }
}

//ContactBlock.scss
.news-room-contact-block {
  .c-contact-block-container {
    container: contact-block / inline-size;
  }

  .c-contact-block {
    position: relative;
    overflow-x: hidden;

    height: unset;
    display: grid;
    grid-template-columns: 150px auto;
    gap: 24px;

    &--vertical {
      display: flex;
      flex-direction: column;
    }

    a,
    p {
      line-height: 120%;
      word-break: break-word;
    }

    .c-contact-block-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      margin-top: 8px;
    }

    .c-contact-block-img {
      aspect-ratio: 1 / 1;
      height: 100%;
      object-fit: scale-down;
    }

    .c-contact-block-name {
      font-weight: 500;

      color: $color-110-warm-grey;
    }

    .c-contact-block-role {
      margin: 8px 0;
    }

    * {
      color: $color-110-warm-grey;
      font-style: normal;
    }

    .c-contact-block-location {
      font-weight: 500;
    }

    .phone-number-wrapper,
    .email-wrapper {
      display: inline-flex;
      align-items: center;
      gap: 8px;

      :first-child {
        width: 20px;
      }
    }

    .c-contact-block-phone-number {
      font-weight: 400;
    }

    .c-contact-block-email {
      font-weight: 400;
    }
  }

  .c-column-block .c-contact-block {
    display: flex;
    flex-direction: column;

    .c-contact-block-img {
      aspect-ratio: initial;
      object-fit: cover;
    }

    .c-contact-block-name {
      color: $color-blue;
      font-weight: normal;
      margin: 24px 0 8px;
    }

    .c-contact-block-role {
      line-height: 167%;
      margin-bottom: 24px;
    }
  }

  @container contact-block (max-width: 400px) {
    .c-contact-block {
      display: flex;
      flex-direction: column;
    }
  }
}

//ColumnBlock.scss
.news-room-three-col-block {
  margin: 16px 0;
  gap: var(--news-room-flex-inner-gap) !important;
}

.news-room-single-three-col-block-news {
  line-height: 0;
  flex: 1;

  &:hover {
    text-decoration: none;
  }
}

.single-three-col-news-block-title {
  color: $color-110-warm-grey;
  margin: 16px 0;
}

@media (max-width: $bkpt-tablet-portrait) {
  .news-room-three-col-block-container {
    gap: 0 !important;
  }
}

//Contacts.scss
.news-room-contact-block {
  border-top: 1px solid $color-blue;
  flex: 1;
  flex-basis: 100%;
  margin: 16px 0;

  &__title {
    margin: 24px 0 32px 0;
    padding: 0;
  }

  &__items {
    gap: calc(2 * var(--news-room-grid-gutter));
  }

  &--half-width {
    flex-basis: 40%;
  }
}

//FinancialNewsBlock.scss
.news-room-financial-news-block {
  background-color: $color-5-warm-grey;
  box-shadow: -20vw 0 0 0 $color-5-warm-grey,
    -40vw 0 0 0 $color-5-warm-grey,
    20vw 0vmax 0 0vmax $color-5-warm-grey,
    40vw 0 0 0 $color-5-warm-grey;
  padding-top: calc(2 * var(--news-room-padding));
  margin: 16px 0;
}

.news-room-financial-news-block-content {
  gap: var(--news-room-grid-gutter) !important;
  padding: var(--news-room-padding) 0 calc(3 * var(--news-room-padding)) 0;
  flex-wrap: inherit;
}

.news-room-single-financial-news {
  padding: calc(var(--news-room-padding) + 8px);
  flex: 1;
  background-color: $color-white;

  .news-block-date {
    text-decoration: none;
    display: inline-block;
  }
}

.financial-news-block-title {
  color: $color-110-warm-grey;
  margin: var(--news-room-margin) 0;
}

@media (max-width: $bkpt-tablet-portrait) {
  .news-room-financial-news-block-content {
    flex-direction: column;
  }

  .news-room-single-financial-news {
    width: 100%;
  }
}

@media screen and (min-width: $bkpt-mobile-landscape) and (max-width: $bkpt-tablet-portrait) {
  .news-room-financial-news-block {
    margin: 32px 0;
  }

  .news-room-financial-news-block-content {
    padding-bottom: calc(2 * var(--news-room-padding));
  }
}

//Heading.scss
.news-room-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--news-room-flex-inner-gap-heading);

  &.is-center {
    justify-content: center;
    align-items: center;

    .news-room-intro>p {
      text-align: center;

    }
  }
}

.news-room-intro {
  max-width: 768px;

  p {
    color: $color-110-warm-grey;
  }
}

@media screen and (max-width: $bkpt-desktop) {
  .news-room-heading h1 {
    font-size: 32px;
  }
}

//LatestNewsBlock.scss
.news-room-latest-news-block-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  column-gap: var(--news-room-flex-inner-gap) !important;
  row-gap: 32px !important;

  .news-block-image,
  .news-block-content {
    flex-basis: 100%;
    width: 100%;
  }

  .news-block {
    gap: var(--news-room-flex-inner-gap-small);
  }

  .news-block-title {
    font-size: 20px;
    line-height: 28px;
  }

  .news-block-image img {
    object-fit: cover;
  }

  .news-block-description {
    display: none;
  }
}

@media screen and (min-width: $bkpt-desktop) {
  .news-room-latest-news-block-content {

    .news-block-image,
    .news-block-content {
      flex-basis: 50%;
      width: 50%;
    }

    .news-block-container:first-child {
      grid-row: 1 / 4;

      .news-block-title {
        font-size: 24px;
        line-height: 32px;
      }

      .news-block-description {
        display: block !important;
        display: -webkit-box !important;
      }

      .news-block {
        flex-direction: column;
      }

      .news-block-image,
      .news-block-content {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $bkpt-desktop) {
  .news-room-latest-news-block-content {
    padding-bottom: var(--news-room-padding);
  }
}

@media screen and (min-width: $bkpt-tablet-portrait) and (max-width: $bkpt-desktop) {
  .news-room-latest-news-block-content {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    padding-bottom: var(--news-room-padding);

    .news-block {
      flex-direction: row !important;
      gap: var(--news-room-flex-inner-gap-small) !important;

      .news-block-image {
        flex-basis: 45% !important;
        width: 45% !important;
      }

      .news-block-content {
        flex-basis: 55% !important;
        width: 55% !important;
      }
    }
  }
}

// mobile landscape breakpoint is used instead of 576px
@media screen and (min-width: $bkpt-mobile-landscape) and (max-width: $bkpt-tablet-portrait) {
  .news-room-latest-news-block-content {
    grid-column-gap: calc(2 * var(--news-room-grid-gutter)) !important;
    grid-template-rows: repeat(2, auto);
  }
}

@media screen and (max-width: $bkpt-mobile-landscape) {
  .news-room-latest-news-block-content {
    grid-template-columns: 1fr;
  }
}

//LatestStoriesBlock.scss
.news-room-latest-stories-block {
  display: flex;
  flex-direction: column;
}

.news-room-latest-stories-block-content {
  display: flex;
  gap: var(--news-room-flex-inner-gap);

  .news-block-container {
    flex: 1;
    padding-bottom: var(--news-room-padding);

    .news-block-image a {
      width: 100%;
    }
  }
}

@media screen and (max-width: $bkpt-tablet-portrait) {
  .news-room-latest-stories-block-content {
    flex-direction: column;
    gap: var(--news-room-flex-inner-gap-small);
  }
}

@media screen and (min-width: $bkpt-mobile-landscape) and (max-width: $bkpt-tablet-portrait) {
  .news-room-latest-stories-block-content {
    .news-block.top-image {
      gap: 0;
    }
  }
}

//PromoBlock.scss
.news-room-promo-block {
  gap: 48px;
  flex-direction: column;
  display: flex;

  .l-section-padding {
    padding: 0 !important;
  }

  .news-block-container {
    margin-top: 0;
  }

  .news-block-image {
    flex-basis: 69%;

    a {
      width: 100%;
    }
  }

  .news-block-content {
    flex-basis: 31%;
  }
}

@media (min-width: $bkpt-desktop) {
  .news-room-promo-block {
    .news-block-content {
      margin-top: 16px;
    }
  }
}

@media (min-width: $bkpt-tablet-portrait) and (max-width: $bkpt-desktop) {
  .news-room-promo-block {
    gap: calc(3 * var(--news-room-grid-gutter));

    .news-block-image,
    .news-block-content {
      padding-top: 0;
      flex-basis: 50%;
      width: 50%;
    }
  }
}

@media (max-width: $bkpt-tablet-portrait) {
  .news-room-promo-block {

    .news-block-image,
    .news-block-content {
      flex-basis: 100%;
      width: 100%;
      padding: 0;
    }
  }
}

//Topics.scss
.news-room-topics {
  background: $color-10-warm-grey;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: calc(2 * var(--news-room-padding));
  margin: 16px 0;
  border-radius: 20px 0px;
}

.news-room-topics-heading {
  padding: var(--news-room-padding);
  margin: 0;
}

.news-room-topics-tabs {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: calc(var(--news-room-flex-inner-gap-small) / 2);
  row-gap: var(--news-room-flex-inner-gap-small);
  padding: var(--news-room-padding);
}

.news-room-topics-tab {
  background-color: $color-white;
  border: 1px solid $color-pantone-gray;
  border-radius: 4px;
  box-sizing: border-box;
  color: $color-110-warm-grey;
  padding: 6px 16px 6px 16px;
  text-align: center;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    border: 1px solid $color-black-light;
    text-decoration: none;
  }
}

.news-room-topics-all-news-link {
  color: $color-blue;
  padding: var(--news-room-padding) 0;

  &:visited {
    color: $color-blue;
  }
}

@media (max-width: $bkpt-desktop) {
  .news-room-topics-heading {
    margin-top: 16px;
  }
}

// mobile landscape breakpoint is used instead of 576px
@media (max-width: $bkpt-mobile-landscape) {
  .news-room-topics {
    box-shadow: -50vw 0vmax 0 0vmax $color-5-warm-grey, 50vw 0vmax 0 0vmax $color-5-warm-grey;
    padding: calc(2 * var(--news-room-padding)) var(--news-room-padding)
  }
}

//HeroBlock.scss
.c-hero-block {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-height: 450px;
  background: black;
  margin: 32px 0;
}

.c-hero-block-image {
  background: no-repeat 50%;
  background-size: cover;
  height: 450px;
  max-height: 450px;
  min-height: 100%;
  object-fit: cover;

  &.overlay-0 {
    opacity: 1;
  }

  &.overlay-20 {
    opacity: 0.8;
  }

  &.overlay-40 {
    opacity: 0.6;
  }

  &.overlay-60 {
    opacity: 0.4;
  }

  &.overlay-80 {
    opacity: 0.25;
  }
}

.c-hero-block-content-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;

  &.is-left {
    justify-content: flex-start;
  }

  &.is-center {
    justify-content: center;
  }

  &.is-right {
    justify-content: flex-end;
  }

  .c-button {
    margin-top: 24px;
  }
}

.c-hero-block-content-wrapper .c-hero-block-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  margin: 32px;
  gap: 4px;

  p {
    text-align: center;
  }

  @media screen and (min-width: $bkpt-mobile-landscape) {
    &.is-left {
      align-items: flex-start;
    }

    &.is-center {
      align-items: center;
    }

    &.is-right {
      align-items: flex-end;
    }

    p {
      &.is-left {
        text-align: left;
      }

      &.is-center {
        text-align: center;
      }

      &.is-right {
        text-align: right;
      }
    }
  }
}

.c-hero-block-title {
  color: $color-white;
  font-size: 42px;
}

.c-hero-block-description {
  color: $color-white;
  font-size: 20px;
}

@media screen and (max-width: $bkpt-desktop) {
  .c-hero-block-title {
    font-size: 36px;
  }

  .c-hero-block-description {
    font-size: 16px;
  }
}

@media screen and (max-width: $bkpt-tablet-portrait-max) {
  .c-hero-block-content-wrapper .c-hero-block-content {
    width: 50%;
  }
}

@media screen and (max-width: $bkpt-mobile-landscape) {
  .c-hero-block-content-wrapper .c-hero-block-content {
    width: 100%;
  }

  .c-hero-block-title {
    font-size: 28px;
  }
}