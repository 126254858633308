.c-filter-block {
  margin-bottom: 1.25rem;
  padding-left: 1.25rem;
  padding-top: 1.25rem;

  .c-filter-block-tag {
    padding: 0.4em 0.9em;
    font-size: 0.875rem;
    margin: 4px;
    border-radius: 3px;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    white-space: nowrap;
    border: 1px solid $color-gray-border;
    background-color: #fff;
    color: $color-110-warm-grey;
    cursor: pointer;
    font-weight: normal;

    &:empty {
      display: none;
    }

    &.c-filter-block-tag-is-active {
      background-color: $color-blue;
      color: #fff;
      border:0px;
    }

    &:hover {
      &:not(.c-filter-block-tag-is-active) {
        background-color: #F5F3F2;
      }
    }
  }

  &.is-related {
    padding: 1rem 0;
    margin-bottom: 0;
    .c-filter-block-tag {
      font-size:0.875rem;      
      margin: 0.5rem;
      transition: background-color 0.2s;
      background-color: $color-20-warm-grey;

      &.c-filter-block-tag-is-active {
        background-color: $color-grey;
        border: none;
      }
    }
  }
}

@include mediaSmallerThan(mobile-l) {
  .c-filter-block {
    margin-top: 20px;
    &.is-related {
      margin: 0;
    }
  }
}
