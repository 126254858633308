$block-name: c-hor-item;

.#{$block-name} {
  padding: 16px;
  a:hover {
    text-decoration: none;
  }
  .#{$block-name}-container {
    display: flex;
    flex-direction: row;
    .#{$block-name}-thumbnail {
      flex: 1 1 0;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      margin-right: 16px;
      img {
        border-radius: 3px;
        max-width: 250px;
      }
    }
    .#{$block-name}-tail {
      flex: 1 1 0;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      .#{$block-name}-title {
        margin: 5px 0;
        font-size: 1rem;
        line-height: 1.3;
        font-weight: 700;
      }
      .#{$block-name}-intro {
        font-size: 0.95rem;
        line-height: 1.5;
        margin: 0;
        color: $color-black-light;
      }
    }
  }
  transition: background-color 0.12s;
  &:hover {
    background-color: rgba(131, 120, 111, 0.09);
  }
}

@include mediaSmallerThan(mobile-l) {
  .#{$block-name} {
    .#{$block-name}-container {
      .#{$block-name}-thumbnail {
        display: none;
      }
      .#{$block-name}-tail {
        .#{$block-name}-title {
          &:after {
            content: "\f105";
            font-family: "ALicons";
            position: absolute;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
