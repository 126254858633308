.c-tag-group-panel {
  width: 100%;
  background-color: $color-gray;
  padding: 10px 20px;
  margin-bottom: 20px;
  @extend .l-flex-container;
  flex-direction: row;
  flex-wrap: wrap;
  a {
    margin-right: 0.5rem;
  }

  .c-tag-group-panel-title {
    @extend .wysiwyg;
    padding: 0;
  }

  .c-tag-item {
    margin: 2px 0;
    border-radius: 3px;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    border: 1px solid $color-gray-border;
    background-color: #fff;
    color: $color-black-light;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.2rem 0.5rem;
    &.is-active {
      background-color: $color-blue;
      color: #fff;
      border-color: $color-blue;
    }
  }
}
