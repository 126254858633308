$block-name: c-search-list-item;

.l-search-items-container{
    display: flex;
    flex-wrap: wrap;
    a{
      text-decoration: none;
    }
}
.#{$block-name}-product {
  position: relative;
  padding-bottom: 22px;
}
.#{$block-name}-image {
  position: relative;
  .#{$block-name}-date-container{
    margin-right: 10px !important;
  }
  .#{$block-name}-title-text{
    overflow: hidden;
  }
}
.#{$block-name} {  
  width: 235px;
  min-width: 200px;
  float: left;
  margin: 12px 12px 30px 12px;
  display: block;
  background-color: $color-gray;
  border-bottom: 1px solid $color-gray;
  .i-container{
    background-color: $color-block-hover-gray;
  }
  .i-title{
    margin-top: -28px;
    label{     
      padding: 2px 8px;
      font-size: 0.8rem;
      font-weight: bold;
      color: #3c3c3c;
      background-color: $color-block-hover-gray;
    }
  }
  a {
    text-decoration: none;
    
  }




  .dl-icon-size1 {
    height: 36px;
    width: 27px;
  }
  
  .dl-icon-size2 {
    height: 22px !important;
    border-radius: 4px;
  }


  .dl-icon-{
    background-position: 0px -20px !important;
  }
  .dl-icon-pdf {
    background-position: -240px -20px !important;
  }

  .dl-icon-file {
    background-position: 0px -20px;

  }
  
  .dl-icon-doc,
  .dl-icon-docx {
    background-position: -80px -20px;
  }
  
  .dl-icon-zip,
  .dl-icon-zipx,
  .dl-icon-rar,
  .dl-icon-tar,
  .dl-icon-gz {
    background-position: -160px -20px;
  }
  
  
  .dl-icon-xls,
  .dl-icon-xlsx {
    background-position: -320px -20px;
  }
  
  .dl-icon-pic,
  .dl-icon-jpg,
  .dl-icon-jpeg,
  .dl-icon-png,
  .dl-icon-gif,
  .dl-icon-bmp {
    background-position: -400px -20px;
  }






















  

  &:hover {
    a {
      text-decoration: none;

    }

    
  }

  .#{$block-name}-thumbnail {
    flex: 1 1 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    mix-blend-mode: multiply;    
    img{
      object-fit: contain;
      height:100%;
    }
    // Setting aspect ratio manually as intrinsic size is not 640*360
    .product-model-thumbnail {
      aspect-ratio: 16/9;
    }
  }

  .#{$block-name}-content {
    flex: 2 1 0;
    flex-grow: 6;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 0px 7px 20px 7px;
    word-wrap: break-word;
  }
  .#{$block-name}-document {
    padding: 0px 7px 7px 7px !important;
  }
  .#{$block-name}-content-2cols {
    flex: 2 1 0;
    flex-grow: 7;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 0px 7px 20px 7px;
  }

  .#{$block-name}-list-image {
    width: 100%;
    display: block;
    border-radius: 3px;
    mix-blend-mode: multiply;
  }
  .#{$block-name}-date-container {
    flex: 1 1 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-width: 100px;
  }
  .#{$block-name}-date-text {
    display: inline-block;
    padding: 0;
    line-height: 1.5;
    color: $color-black-light;
    font-size: 0.8rem;
  }
  .#{$block-name}-mini-date-container {
    display: none;
  }

  .#{$block-name}-title-text {
    margin: 5px 0 0px;
    padding: 0;
    font-size: 0.8rem;
    line-height: 1.7;
    font-weight: 700;
    color: #3c3c3c;
    overflow: hidden;
  }

  .#{$block-name}-content-text, .#{$block-name}-content-text  * {
    font-size: 0.8rem;
    line-height: 1.5;
    color: #3c3c3c;
  }
  .#{$block-name}-document-type {
    font-size: 0.8rem;
    line-height: 1.5;
    font-style: italic;
    color: #3c3c3c;
  }
  .#{$block-name}-document-footer {
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    bottom: 4px;
    margin: 0 7px;
  }
  .#{$block-name}-image-footer {
    font-size: 0.8rem;
    display: flex;
    margin: 0 7px;
  }
  .#{$block-name}-pdf {
    font-size: 0.8rem;
    background: $color-blue;
    display: inline;
    color: white;
    padding: 0px 5px;
    border-radius: 3px;
    margin-right: 10px !important;
  }
}
@media (min-width: 953px ) and (max-width: 1264px) {
  .#{$block-name} {
    width: 30.2%;
  }
}
@media (min-width: 641px) and (max-width: 952px) {
  .#{$block-name} {
    width: 45.4%;
  }
}
@media (max-width: 640px) {
  .#{$block-name} {
    width: 95%;
  }
}
@include mediaSmallerThan(mobile-l) {
  .#{$block-name} {

    .#{$block-name}-content {
      flex: 2;
    }
    .#{$block-name}-mini-date-container {
      display: inherit;
    }
    .#{$block-name}-title-text {
      line-height: 1.4;
    }
  }
}
