.c-headerbar {
  min-height: 48px;
  background-color: white;
  margin-top: -1px;

  &.with-bg {
    background-color: $color-5-warm-grey;
  }

  &.sticky {
    position: fixed;
    width: 100%;
    z-index: 100;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  }

  &.c-headerbar-submenu {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 2px 15px rgba(0, 0, 0, 0.1);
  }
}

.c-headerbar-social {
  .c-button {
    height: 42px;
    padding: 8px 12px;
  }
}

.c-headerbar-container {
  height: 100%;
  margin: 0 auto;
  max-width: $max-page-width;

  &.page-with-submenu {
    max-width: none;
    background-color: $color-5-warm-grey;
  }

  &.c-headerbar-container-submenu {
    justify-content: center;
    padding: 0px $default-padding;
  }

  &.loading {
    margin: unset;
    max-width: unset;

    .c-breadcrumb {
      height: 48px;
    }

    .c-breadcrumb-container {
      @include c-skeleton-horizontal-loading($color-gray);
      min-width: 500px;
      height: 100%;
      border-radius: 8px;
    }

    .c-headerbar-social {
      @include c-skeleton-horizontal-loading($color-gray);
      min-width: 310px;
      height: 100%;
      border-radius: 8px;
    }

    @media screen and (max-width: $bkpt-tablet-portrait-max) {
      .c-breadcrumb-container {
        min-width: 150px;
      }

      .c-headerbar-social {
        min-width: 100px;
      }
    }
  }

}

.c-headerbar-contact {
  display: inline-flex;

  .contact-margin {
    margin-left: 1em;
    border-radius: 3px;
  }

  @media (max-width: $bkpt-mobile-portrait) {
    .contact-margin {
      margin-left: 4px;
      padding: 0.4rem 0.65rem;
    }
  }
}

@media (max-width : $bkpt-tablet) {
  .shadow {
    box-shadow: 0 2px 25px rgb(0 0 0 / 10%);
  }
}