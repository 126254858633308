$list: 10 20 25 33 50 66 75 100;
$flexOrder: 1 2 3 4;

.l-section {
  flex: 1 1 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  box-sizing: border-box;
  min-width: 220px;

  &.is-reset {
    .l-section-block {
      padding: 0;
    }
  }

  &.hide-in-desktop {
    display: none;
  }

  &.is-solid {
    min-width: auto;
  }

  &.l-section-100 {
    flex-basis: 100%;
  }

  &.l-section-75 {
    flex-basis: 75%;
    max-width: 75%;
  }

  &.l-section-66 {
    flex-basis: 66%;
    max-width: 66%;
  }

  &.l-section-50 {
    flex-basis: 50%;
    max-width: 50%;
  }

  &.l-section-33 {
    flex-basis: 33%;
    max-width: 33.33%;
  }

  &.l-section-25 {
    flex-basis: 24%;
    max-width: 25%;
  }

  &.l-section-20 {
    flex-basis: 20%;
    max-width: 20%;
  }

  &.l-section-main {
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 0;
    max-width: 58.33%;
  }

  &.l-section-side {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin-left: 8.3333%;
  }
  @include mediaSmallerThan(mobile-l) {
    @each $i in $flexOrder {
      &.flex-order-#{$i} {
        order: #{$i};
      }
      &.img-no-paddiing {
        padding: 0px;
      }
    }
    .c-image-file {
      &.object-fit {        
        height: 220px;
        width: 100%;
      }
    }
    &.hide-in-desktop {
      display: block;
    }
  }

  @include mediaSmallerThan(tablet) {
    &.img-no-paddiing {
      padding: 0px;
    }
  }
}

.block-100 {
  @extend .l-section;
  @extend .l-section-block;
  @extend .l-section-100;
  min-width: 100%;
  padding-left: 0;
  padding-right: 0;

  @include mediaSmallerThan(tablet) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include mediaSmallerThan(mobile-l) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: $bkpt-tablet) {
  .c-latest-news-block-body {
    .c-updated-news-block-l-section-50 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

@include mediaSmallerThan(tablet) {
  .l-section {
    flex-basis: 100%;
    @each $i in $list {
      &.l-section-#{$i} {
        &:not(.is-solid) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }

    &.l-section-block {
      padding-left: 15px;
      padding-right: 15px;
    }
    &.l-section-side,
    &.l-section-main {
      margin-left: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.l-section-block {
  padding: 0 16px;
}

.l-section-padding {
  padding: 16px;
  &.l-section-padding-XL {
    margin-top: 7.5rem;
    @include mediaSmallerThan(tablet) {
      padding-top: 0px;
    }
  }
}
.l-document-no-result {
  display: none;
}

.l-service-description {
  h2,
  h4 {
    &:first-child {
      padding-top: 0;
      margin-top: 0;
    }
  }
  h3,
  h5,
  h6,
  p,
  img {
    &:first-child {
      padding-top: 0;
    }
  }
}

.l-service-list-intro {
  padding-top: 0px;
}

.l-content-pt0 {
  .l-section {
    @extend .l-service-description;
  }
}

// Desktop
@include mediaGreaterThan(mobile-l) {
  @include mediaSmallerThan(tablet) {
    .l-section-50-md {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

// Tablet
@include mediaGreaterThan(tablet) {
  .l-section-33-lg {
    flex-basis: 33%;
    max-width: 33.33%;
  }

  // A quick fix to make promotionblock 2 column in < 50% mode
  .l-section-20,
  .l-section-25,
  .l-section-33,
  .l-section-50 {
    .l-section-33-lg {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

.l-page-industry .items-padding{
    padding: 16px 0;
  }