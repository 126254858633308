$block-name: c-info-box;

.#{$block-name} {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  z-index: 7;
  overflow: hidden;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;

  &.is-active {
    width: 100%;
    overflow: visible;
    z-index: 8;
    .#{$block-name}-text {
      opacity: 1;
    }
    &:after {
      font-family: Arial, sans-serif;
      margin-right: -15px;
      content: "X";
    }
  }

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    content: "\e605";
    font-family: ALicons;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
    z-index: 1;
  }

  .#{$block-name}-arrow {
    width: 20px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    &:before {
      position: absolute;
      top: -12px;
      bottom: 0;
      width: 0;
      height: 0;
      left: 50%;
      content: "";
      margin-left: -6px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid rgba(5, 5, 5, 0.9);
    }
  }
  .#{$block-name}-text {
    position: relative;
    width: calc(100% + 30px);
    height: auto;
    padding: 15px;
    box-sizing: border-box;
    background-color: rgba(5, 5, 5, 0.9);
    opacity: 0;
    border-radius: 3px;
    margin-left: -15px;

    p {
      color: white;
    }
    ul {
      padding-left: 0;
      li {
        list-style-type: none;
        padding-left: 2rem;
        margin: 0.75em 0;
        font-size: 16px;
        line-height: 1.75;
        text-align: left;
        position: relative;
        color: white;
        &:before {
          content: "\25CB";
          font-family: ALicons;
          position: absolute;
          left: 0;
          font-size: 1.6rem;
          top: -0.8rem;
          color: white;
        }
      }
    }

    .#{$block-name}-header {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      line-height: 1.4;
      font-weight: 700;
    }
    .#{$block-name}-content {
      font-size: 0.9rem;
      line-height: 1.3;
    }
  }
}

@include mediaGreaterThan(tablet) {
  .#{$block-name} {
    &:hover {
      width: 100%;
      overflow: visible;
      .#{$block-name}-text {
        opacity: 1;
      }

      &:after {
        content: "";
      }
    }
  }
}
