.c-list-block {
  display: block;
  padding: 20px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(131, 120, 111, 0.09);
    text-decoration: none;
  }

  .c-list-block-img {
    img {
      width: 100%;
    }
  }

  .c-list-block-tail {
    .c-list-block-title {
      font-size: 0.95rem;
      font-weight: 700;
    }

    .c-list-block-date {
      margin: 5px 0px;
      font-size: 0.9rem;
      color: $color-grey;
    }
  }
}