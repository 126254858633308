.l-flex-item {
  flex: 1 1 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  &.l-flex-item-2 {
    flex: 2 1 0;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 0;
  }
  &.l-flex-item-6 {
    flex: 6 1 0;
    flex-grow: 6;
    flex-shrink: 1;
    flex-basis: 0;
  }

  &.l-flex-item-100 {
    flex-basis: 100%;
  }

  &.l-flex-item-50 {
    flex-basis: 50%;
  }
  &.l-flex-item-66 {
    flex-basis: 66%;
  }
  &.l-flex-item-33 {
    flex-basis: 33%;
  }
  &.l-flex-item-25 {
    flex-basis: 25%;
  }
  &.l-flex-item-75 {
    flex-basis: 75%;
  }

  &.l-flex-item-20 {
    flex-basis: 20%;
  }

  &.l-flex-item-3 {
    flex: 3 1 0;
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: 0;
  }
}
