.service-page-icon {
  display: block;
  margin: auto;
  padding-bottom: 16px;
}

.big-article-image {
  height: 400px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 50px;
}
