html {
  &.campaign {
    header {
      &.campaign-header {
        .campaign-headline {
        }

        .campaign-change-country {
          .input-select {
          }
        }
        .header-inner {
          margin: auto;
          min-width: inherit;
        }

        .bottom-menu {
          .menu-toplevel-link {
            &:hover {
              text-decoration: none;
            }
            &:visited {
              color: $color-blue;
            }
          }
        }
      }
    }

    footer {
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .footer-bar,
      .footer-contact-container {
        min-width: inherit;
        margin: auto;
      }

      .footer-contact-link {
        a {
          font-size: inherit;
          line-height: inherit;
          color: inherit;
          &:hover {
            color: $color-black-light;
          }
        }
      }
    }

    .bounds {
      margin: auto;
    }
    .panel-link-default,
    .menu-link {
      color: $color-black-light;
      &:visited {
        color: $color-black-light;
      }
      &:hover {
        text-decoration: none !important;
      }
    }

    .c-hero-banner-card {
      position: absolute;

      .c-hero-banner-card-header {
        font-size: 4.8rem;
      }
      .c-hero-banner-card-subtitle {
        font-size: 2.2rem;
      }
      .c-hero-banner-card-paragraph {
        font-size: 1.5rem;
      }
    }
  }
}

@include mediaSmallerThan(desktop) {
  html {
    &.campaign {
      .menu-toplevel-link {
        font-size: 14px;
        padding: 0.5rem 0 0.5rem 1rem;
      }
    }
  }
}

@include mediaSmallerThan(tablet) {
  html {
    &.campaign {
      .c-hero-banner {
        max-height: none;

        .c-hero-banner-content {
          background-color: transparent;
        }
      }

      .c-hero-banner-card {
        position: relative;
      }
    }
  }
}

.social-box, .mobile .mobile-social-box-small, .desktop .footer-contact-container .footer-contact-social .mobile-social-box-small {
  color: $color-blue;
  fill: $color-blue;

  .icon-alignment {
    vertical-align: text-top;
  }
}

.footer-contact-social {
  .footer-social-icons {
    display: flex;
    justify-content: center;
  }
}

.social-box-small {
  .icon-linkedin, .icon-envelope {
    vertical-align: middle;
  }
}

.footer-bar-wrap, .panel-primary, .sidebar-callout-primary-header, .icon-bluebox:after, .textbox-panel, .spot-panel-primary-fullwidth, .spot-primary, .desktop .quote-container, .accord-head-default, .accord-head-primary, .mobile-header-submenu, .mobile-header-search-box, .hero-spot-primary, .mobile .hero .slick-list {
  background-color: $color-blue;
}

.menu-toplevel-link {
  &:after {
    background-color: $color-blue;
  }
}

.desktop .footer-contact-container .footer-contact-link, .desktop .footer-contact-container .footer-contact-phone, .desktop .footer-contact-container .footer-contact-mail {
  color: $color-black-light;
}

.focus-gray{
  &:focus {
    -webkit-box-shadow: 5px 0 $color-blue inset;
    background-color: $color-gray;
    box-shadow: 5px 0 $color-blue inset;
    outline: none;
  }
}

.button-primary, .footer-button, .desktop .hero-button-primary, .mobile .hero-wrap.standalone .hero-button-primary, .wysiwyg input[type="submit"], .contact-item input[type="submit"], .mobile .footer-contact-container .footer-contact-phone, .mobile .footer-contact-container .footer-contact-mail {
  background-color: $color-blue;
  border: 1px solid $color-blue;
  color: #fff;
}

.button-light-text-blue, .spot-fullwidth-button, .desktop .hero-button-light, .mobile .hero-button-light {
  background-color: #fff;
  border: 1px solid $color-gray;
  color: $color-blue;
}

.not-touch .button-light-text-blue:hover {
  background-color: $color-white-gray;
  border-color: $color-white-gray;
  color: $color-blue;
}
.tag.tag-active, .tag:active {
  background-color: $color-blue;
  border-color: $color-blue;
  color: #fff;
}
.highlight-primary, .sidebar-primary-header, .sidebar-callout-header, .sidebar-callout-primary-header, .accord-head-default, .accord-head-primary, .category-results-container, .contact-highlight-bottom {
  border-color: $color-blue;
}
.highlight-top-primary {
  border-top-color: $color-blue;
}
.list-primary{
  li {
    color: $color-blue;
    &:before{
      color: $color-blue;
    }
  }
} 

.content-tab-item-active {
  &:before {
    background-color: $color-blue;
    border-left: 1px solid $color-blue;
    border-right: 1px solid $color-blue;
  }
}

.top-right-menu:hover .highlight-menu-icons div {
  opacity: 0.5;
}

.top-right-menu:hover .highlight-menu-icons div:hover {
  opacity: 1;
}

.footer-social-icons:hover .highlight-menu-icons a {
  opacity: 0.5;
}

.footer-social-icons:hover .highlight-menu-icons a:hover {
  opacity: 1;
}
